import React from 'react';
// import PropTypes from 'prop-types';

import RootLayout from 'components/RootLayout';
import IconTemplatesRoot from './pages/IconTemplatesRoot';
import IconTemplatesCreateUpdate from './pages/IconTemplatesCreateUpdate';
import IconTemplatesAlertSettings from './pages/IconTemplatesAlertSettings';

function IconTemplates() {
  return (
    <RootLayout>
      <IconTemplatesRoot path="/" />
      <IconTemplatesCreateUpdate path="/new" />
      <IconTemplatesCreateUpdate path="/edit/:id" />
      <IconTemplatesAlertSettings path="/new/alert-settings" />
      <IconTemplatesAlertSettings path="/edit/:id/alert-settings" />
    </RootLayout>
  );
}

IconTemplates.propTypes = {};

IconTemplates.defaultProps = {};

export default IconTemplates;
