import React from 'react';
import PropTypes from 'prop-types';

import { Spin } from 'antd';

import useGetNotes from 'hooks/useGetNotes';
import useUnreadNotes from 'hooks/useUnreadNotes';

function UnreadNotes({ recipientId, userId, ...rest }) {
  const { initialLoading: notesLoading, notes } = useGetNotes(recipientId);
  const unreadNotes = useUnreadNotes(notes);

  // get notes for this recipient
  if (notesLoading || unreadNotes === null) {
    return <Spin size="small" />;
  }
  return <span>{unreadNotes.length}</span>;
}

UnreadNotes.propTypes = {
  recipientId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
};

UnreadNotes.defaultProps = {};

export default UnreadNotes;
