// DO NOT EDIT - This file is automatically generated via yarn buildTranslations
// Request access here:
// https://docs.google.com/spreadsheets/d/1HQdC8YZRPFtsn1a427uh3m_hhrbqqYBUdHpct65NwJ0/edit#gid=1763868262

export default {
  common: {
    back: 'Espalda',
    cancel: 'Cancelar',
    close: 'Cierre',
    date: 'Fecha',
    email: 'Correo Electrónico',
    goodMorning: 'Buenos días',
    goodAfternoon: 'Buenas tardes',
    goodEvening: 'Buena noches',
    new: 'Nuevo',
    remove: 'Eliminar',
    next: 'Siguiente',
    of: 'de',
    select: 'Seleccione',
    startTyping: 'Empiece a escribir',
    submit: 'Enviar',
    time: '\nHora',
    trackCare: 'Cuidado de la Pista',
    no: 'No',
    yes: 'Si',
  },
  login: {
    username: 'Nombre de usuario',
    error: 'Acceso fallido. Por favor intente nuevamente',
    password: 'Contraseña',
    language: 'Idioma',
    login: 'Iniciar Sesión',
    showPassword: 'Mostrar Contraseña',
    forgotPassword: 'Se te olvidó tu contraseña',
  },
  forgotPassword: {
    error: 'Falló el restablecimiento de la contraseña. Vuelva a intentarlo.',
    success:
      'Consulte su correo electrónico para obtener instrucciones para restablecer la contraseña',
    title: 'Restablecimiento de contraseña',
  },
  nav: {},
  careRecipients: {},
  officeCommunication: {},
  iconTemplates: {},
  familyChat: {},
  schedule: {},
  planOfCare: {},
};
