import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Button } from 'antd';

import { useTranslation } from 'react-i18next';

const Center = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  margin-top: -12px;
`;

function LoadMore({ loading, onClick }) {
  const { t } = useTranslation(['officeCommunication']);

  return (
    <Center>
      <Button loading={loading} onClick={onClick} type="primary">
        {t('officeCommunication:loadMore')}
      </Button>
    </Center>
  );
}

LoadMore.propTypes = {
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

LoadMore.defaultProps = {};

export default LoadMore;
