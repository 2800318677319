import React from 'react';
// import PropTypes from "prop-types";

function NotFound() {
  return (
    <div className="NotFound">
      <span>NotFound</span>
    </div>
  );
}

NotFound.propTypes = {};

NotFound.defaultProps = {};

export default NotFound;
