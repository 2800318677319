import React from 'react';
import http from 'utils/http';
import endpoints from 'utils/endpoints';

const defaultIcons = {
  activities: [],
  mood_behavior: [],
  track_care: [],
  vitals: [],
};

function useTemplateState(copiedIcons, id, defaultName) {
  const [search, setSearch] = React.useState('');
  const [name, setName] = React.useState(defaultName || '');
  const [availableIcons, setAvailableIcons] = React.useState([]);
  const [selectedIcons, setSelectedIcons] = React.useState(
    copiedIcons || defaultIcons
  );
  const [selectedCategory, setSelectedCategory] = React.useState(null);

  React.useEffect(() => {
    http(endpoints.getIcons)
      .then(({ data }) => {
        setAvailableIcons(data.data);
      })
      .catch(console.log);
  }, []);

  function iconObjectToList(obj) {
    if (!obj) {
      return [];
    }
    return Object.keys(obj).reduce((acc, key) => {
      const theseIcons = obj[key].map(el => ({ ...el, category: key }));
      return acc.concat(theseIcons);
    }, []);
  }

  function handleIconRemove(icon) {
    const newIcons = selectedIcons;
    const newCategoryIcons = newIcons[selectedCategory].filter(
      el => el.id !== icon.id
    );
    newIcons[selectedCategory] = newCategoryIcons;
    setSelectedIcons({ ...newIcons });
  }

  function filteredIcons() {
    const selected = iconObjectToList(selectedIcons);
    const iconsNotSelected = availableIcons.filter(
      el => !selected.find(e => e.id === el.id)
    );

    if (search.length) {
      return iconsNotSelected.filter(icon =>
        icon.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    return iconsNotSelected;
  }

  function selectIcon(icon) {
    const newIcons = selectedIcons;
    let newCategoryIcons;
    // We add category here to make for easier updates in the alert settings page
    const thisIcon = { ...icon, category: selectedCategory };
    if (newIcons[selectedCategory]) {
      newCategoryIcons = newIcons[selectedCategory].concat(thisIcon);
    } else {
      newCategoryIcons = [thisIcon];
    }
    newIcons[selectedCategory] = newCategoryIcons;
    setSelectedIcons({ ...newIcons });
  }

  const saveTemplate = () => null;

  return {
    filteredIcons,
    handleIconRemove,
    name,
    saveTemplate,
    search,
    selectIcon,
    selectedIcons,
    setSearch,
    setSelectedCategory,
    setName,
  };
}

export default useTemplateState;
