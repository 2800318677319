import React from 'react';
import PropTypes from 'prop-types';
import http from 'utils/http';
import endpoints from 'utils/endpoints';
import { navigate } from '@reach/router';
import { authSuccess } from 'reducers/auth';

import Loading from 'components/Loading';
import { message } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function AuthRedirect({ location }) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['login']);
  const tok = useSelector(state => state.auth.token);

  React.useEffect(() => {
    // MTS - We do this so we only navigate once the redux auth store exists
    if (tok) {
      navigate('/care-recipients');
    }
  }, [tok]);

  React.useEffect(() => {
    http(`${endpoints.account}${location.search}`)
      .then(({ data }) => {
        const { policies } = data;
        const { user, token } = data.data;
        dispatch(authSuccess({ user: user, policies, token }));
      })
      .catch(err => {
        message.error(t('login:error'));
        navigate('/');
      });
    // eslint-disable-next-line
  }, []);

  return <Loading />;
}

AuthRedirect.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

AuthRedirect.defaultProps = {
  location: {
    search: '',
  },
};

export default AuthRedirect;
