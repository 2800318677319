import React from 'react';
import { useTranslation } from 'react-i18next';

import Link from 'components/Link';
import UnreadNotes from './UnreadNotes';

function useColumns() {
  const { t } = useTranslation(['common', 'careRecipients']);
  const columns = [
    {
      dataIndex: 'name',
      title: t('common:name'),
      render: (text, data) => <Link to={`${data.key}`}>{text}</Link>,
    },
    {
      dataIndex: 'careTeam',
      title: t('careRecipients:careTeams'),
    },
    {
      dataIndex: 'carePlan',
      title: t('careRecipients:carePlan'),
    },
    {
      dataIndex: 'openAlerts',
      title: t('careRecipients:openAlerts'),
    },
    // {
    //   dataIndex: 'lastObservation',
    //   title: t('careRecipients:lastObservation'),
    // },
    {
      dataIndex: 'unreadNotes',
      title: t('careRecipients:unreadNotes'),
      render: (a, data) => (
        <UnreadNotes recipientId={data.recipientId} userId={data.userId} />
      ),
    },
  ];
  return columns;
}

export default useColumns;
