import axios from 'axios';
import appReducer from 'reducers';

import { message } from 'antd';
import i18n from 'i18n';

const { store } = appReducer;
const API_BASE = process.env.REACT_APP_API_BASE;

axios.defaults.baseURL = `https://${API_BASE}`;
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.headers['Accept'] = 'application/json';
axios.defaults.headers['Accept-Version'] = 'v2';
axios.defaults.headers['eCaring-API-Version'] = '1';

// Response interceptor
axios.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  // MTS - This is the interceptor for expired tokens
  error => {
    if (error.response.status === 401 && !error.config.url !== '/') {
      // MTS - This will handle reseting the navigation stack and clearing the app
      // state.
      store.dispatch({ type: 'auth/logout' });
    }
    if (error.response.status === 403) {
      console.log({ error });
      message.error(i18n.t('common:permissionError'));
      store.dispatch({ type: 'auth/logout' });
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axios;
