import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';
import axios from 'axios';

import { useSelector } from 'react-redux';

function PrivateRoute({ as: Component, rest }) {
  const token = useSelector(state => state.auth.token);

  if (!token) {
    const from = encodeURI(window.location.pathname);
    return <Redirect from="" to={`/?from=${from}`} noThrow />;
  }

  // MTS 10/21/2020 - Research to see if this is necessary.
  // We are going to reattach the auth token here for axios
  axios.defaults.headers['Authorization'] = `Bearer ${token}`;

  return <Component {...rest} />;
}

PrivateRoute.propTypes = {
  as: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
};

PrivateRoute.defaultProps = {};

export default PrivateRoute;
