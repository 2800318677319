import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Observation from 'components/Observation';
import { Tabs } from 'antd';

import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;

const IconArea = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  grid-gap: 10px;
  width: 100%;
`;

function IconTabs({ hoverIcon, icons, onIconClick, onTabSelect, ...rest }) {
  const { t } = useTranslation(['common', 'iconTemplates']);

  const tabs = [
    { display: t('common:trackCare'), key: 'track_care' },
    { display: t('common:activities'), key: 'activities' },
    { display: t('common:vitals'), key: 'vitals' },
    { display: t('common:moodBehavior'), key: 'mood_behavior' },
  ];

  React.useEffect(() => {
    onTabSelect(tabs[0].key);
    // eslint-disable-next-line
  }, []);

  function handleClick(icon) {
    onIconClick(icon);
  }

  function getIconsFromKey(key) {
    return icons[key] || [];
  }

  function sendChange(i) {
    const index = parseInt(i, 10);
    onTabSelect(tabs[index].key);
  }

  return (
    <Tabs defaultActiveKey="0" size="small" onChange={sendChange}>
      {tabs.map((tab, i) => (
        <TabPane
          key={i}
          tab={`${tab.display} (${getIconsFromKey(tab.key).length})`}
        >
          <IconArea>
            {getIconsFromKey(tab.key).map(icon => (
              <Observation
                key={icon.id}
                hoverIcon={hoverIcon}
                onClick={onIconClick ? () => handleClick(icon) : undefined}
                {...icon}
              />
            ))}
          </IconArea>
        </TabPane>
      ))}
    </Tabs>
  );
}

IconTabs.propTypes = {
  hoverIcon: PropTypes.oneOf(['add', 'remove']),
  icons: PropTypes.any.isRequired,
  onIconClick: PropTypes.func,
  onTabSelect: PropTypes.func.isRequired,
};

IconTabs.defaultProps = {
  hoverIcon: null,
  onTabSelect: () => null,
};

export default IconTabs;
