import React from 'react';
import { useTranslation } from 'react-i18next';

import DetailsCell from './DetailsCell';
import FrequencyCell from './FrequencyCell';
import UrgencyCell from './UrgencyCell';

function useColumns(handleIconUpdate) {
  const { t } = useTranslation(['iconTemplates']);
  const columns = [
    {
      dataIndex: 'icon',
      title: t('iconTemplates:alertTrigger'),
      render: (text, data) => data.name,
    },
    {
      dataIndex: '',
      title: t('iconTemplates:details'),
      render: (text, data) => (
        <DetailsCell handleIconUpdate={handleIconUpdate} icon={data} />
      ),
    },
    {
      dataIndex: '',
      title: t('iconTemplates:frequency'),
      render: (text, data) => (
        <FrequencyCell handleIconUpdate={handleIconUpdate} icon={data} />
      ),
    },
    {
      dataIndex: '',
      title: t('iconTemplates:urgency'),
      render: (text, data) => (
        <UrgencyCell handleIconUpdate={handleIconUpdate} icon={data} />
      ),
    },
  ];
  return columns;
}

export default useColumns;
