import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, Input } from 'antd';

import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  margin-left: 16px;
`;

const StyledNewItem = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

function NewItem({ onCancel, onSave }) {
  const { t } = useTranslation(['checklist', 'common']);
  const [text, setText] = React.useState('');

  return (
    <StyledNewItem>
      <Input
        onChange={e => setText(e.target.value)}
        placeholder={t('planOfCare:newItemText')}
        value={text}
      />
      <Wrapper>
        <Button onClick={onCancel}>{t('common:cancel')}</Button>
        <Button
          type="primary"
          onClick={() => onSave(text)}
          style={{ marginLeft: 8 }}
        >
          {t('planOfCare:saveItem')}
        </Button>
      </Wrapper>
    </StyledNewItem>
  );
}

NewItem.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

NewItem.defaultProps = {};

export default NewItem;
