import React from 'react';
import PropTypes from 'prop-types';

import { Select } from 'antd';

const { Option } = Select;

function UrgencyCell({ handleIconUpdate, icon, ...rest }) {
  const options = ['urgent', 'not urgent'];
  // We don't show frequency if we don't have an alert configured.
  if (!icon.alert_setting) {
    return null;
  }
  return (
    <Select
      defaultValue={options[0]}
      onChange={value =>
        handleIconUpdate({ icon, value: value === 'urgent', key: 'urgent' })
      }
      style={{ minWidth: 120 }}
      value={icon.alert_setting.urgent ? options[0] : options[1]}
    >
      {options.map((option, i) => (
        <Option key={i} value={option}>
          {option}
        </Option>
      ))}
    </Select>
  );
}

UrgencyCell.propTypes = {
  handleIconUpdate: PropTypes.func.isRequired,
  icon: PropTypes.any.isRequired,
};

UrgencyCell.defaultProps = {};

export default UrgencyCell;
