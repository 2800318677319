import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import endpoints from 'utils/endpoints';
import { sendError } from 'utils/helpers';

import ChatUI from '../../components/ChatUI';
import MessageList from '../../components/ChatUI/MessageList';
import TextEntry from '../../components/ChatUI/TextEntry';
import { message } from 'antd';

import { addReadNotes } from 'reducers/readNotes';
import { useDispatch, useSelector } from 'react-redux';
import useGetNotes from 'hooks/useGetNotes';
import { useTranslation } from 'react-i18next';
import useUnreadNotes from 'hooks/useUnreadNotes';

function OfficeCommunication({ recipient, ...rest }) {
  const { t } = useTranslation(['officeCommunication']);
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const listRef = React.useRef(null);

  const [sendingMessage, setSendingMessage] = React.useState(false);
  const {
    addNote,
    incrementPage,
    loading,
    maxPagesReached,
    notes,
  } = useGetNotes(recipient.id);
  const unreadNotes = useUnreadNotes(notes);

  // on unmount, mark notes as read
  React.useEffect(() => {
    return () => dispatch(addReadNotes(unreadNotes));
    // eslint-disable-next-line
  }, []);

  function handleSubmit(msg) {
    setSendingMessage(true);

    const transporter = axios.create();
    transporter.defaults.headers['Content-Type'] = 'multipart/form-data';
    transporter.defaults.headers['HTTP_ACCEPT_ENCODING'] = 'gzip, deflate, br';

    const formData = new FormData();
    formData.append('token', token);
    formData.append('body', msg);
    formData.append('care_recipient_id', recipient.id);
    formData.append('created_at', new Date());
    const request = {
      data: formData,
      method: 'POST',
      url: endpoints.createNote,
    };
    transporter(request)
      .then(res => {
        addNote(res.data);
      })
      .catch(err => {
        sendError('Error creating note', err);
        message.error(t('officeCommunication:noteCreationError'));
      })
      .finally(() => {
        setSendingMessage(false);
        if (listRef && listRef.current) {
          listRef.current.scrollToBottom();
        }
      });
  }

  const list = (
    <MessageList
      ref={listRef}
      incrementPage={maxPagesReached ? null : incrementPage}
      loading={loading}
      messages={notes}
    />
  );
  const textEntry = (
    <TextEntry loading={sendingMessage} onSendMessage={handleSubmit} />
  );

  return (
    <ChatUI
      list={list}
      recipient={recipient}
      textEntry={textEntry}
      title={t('officeCommunication:officeCommunication')}
    />
  );
}

OfficeCommunication.propTypes = {
  id: PropTypes.number.isRequired,
  recipient: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }).isRequired,
};

OfficeCommunication.defaultProps = {};

export default OfficeCommunication;
