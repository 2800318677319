import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { ArrowRightOutlined } from '@ant-design/icons';
import ButtonLink from 'components/ButtonLink';
import IconTabs from '../../../components/IconTabs';
import { Typography } from 'antd';

import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const StyledIconTemplateViewing = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 24px;
`;

const StyledHeaderWithTabs = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

function IconTemplateViewing({ icons, name, ...rest }) {
  const { t } = useTranslation(['common', 'iconTemplates']);
  return (
    <StyledIconTemplateViewing>
      <StyledHeaderWithTabs>
        <TitleSection>
          <Title level={4} style={{ margin: 0, padding: 0 }}>
            {name}
          </Title>
          <ButtonLink state={{ icons }} to="new" type="primary">
            {t('iconTemplates:copyTemplate')}
            <ArrowRightOutlined style={{ marginLeft: 8 }} />
          </ButtonLink>
        </TitleSection>
        <IconTabs icons={icons} />
      </StyledHeaderWithTabs>
    </StyledIconTemplateViewing>
  );
}

IconTemplateViewing.propTypes = {
  icons: PropTypes.shape({
    activities: PropTypes.array,
    mood_behavior: PropTypes.array,
    track_care: PropTypes.array,
    vitals: PropTypes.array,
  }),
  name: PropTypes.any.isRequired,
};

IconTemplateViewing.defaultProps = {
  icons: {
    activities: [],
    mood_behavior: [],
    track_care: [],
    vitals: [],
  },
};

export default IconTemplateViewing;
