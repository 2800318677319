import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Link as ReachLink } from '@reach/router';
import { Typography } from 'antd';

const { Text } = Typography;

const Color = styled.span`
  color: ${({ theme }) => theme.colors.blue6};
`;

function Link({ children, to, ...rest }) {
  return (
    <ReachLink to={to} {...rest}>
      <Text type="primary">
        <Color>{children}</Color>
      </Text>
    </ReachLink>
  );
}

Link.propTypes = {
  children: PropTypes.any.isRequired,
  to: PropTypes.string.isRequired,
};

Link.defaultProps = {};

export default Link;
