import React from 'react';
import { w3cwebsocket as ws } from 'websocket';
import { useSelector } from 'react-redux';

function useWebSocket(recipientId, incrementUnreadCount) {
  const userId = useSelector(state => state.auth.user.id);
  const token = useSelector(state => state.auth.token);
  const params = `care_recipient_id=${recipientId}&token=${token}`;
  const uri = `wss://${process.env.REACT_APP_API_BASE}/cable?${params}`;

  const socketMessages = React.useRef([]);
  const socket = React.useRef();
  const [mounted, setMounted] = React.useState(false);
  // We use lastMessage to trigger rerenders since socketMessages has to be
  // a ref instead of state.
  // eslint-disable-next-line
  const [lastMessage, setLastMessage] = React.useState(0);

  React.useEffect(() => {
    return () => {
      socket.current.close();
      socket.current = null;
    };
  }, [recipientId]);

  React.useEffect(() => {
    if (!mounted) {
      socket.current = new ws(uri);
      setMounted(true);
      socket.current.onmessage = msg => onMessage(msg);
      socket.current.onopen = open => {
        console.log({ open });
      };
      socket.current.onerror = error => {
        console.log({ error });
      };
      socket.current.onclose = close => {};
    }
    // eslint-disable-next-line
  }, [mounted]);

  const updateLastRead = React.useCallback(() => {
    const date = new Date().toISOString();
    const msg = {
      command: 'message',
      identifier: JSON.stringify({
        channel: 'MessagesChannel',
        care_recipient: recipientId,
      }),
      data: JSON.stringify({
        action: 'read',
        last_read_at: date,
      }),
    };
    socket.current.send(JSON.stringify(msg));
    // eslint-disable-next-line
  }, [socket, recipientId]);

  const subscribe = React.useCallback(
    socket => {
      console.log('subscribing to channel');
      const msg = {
        command: 'subscribe',
        identifier: JSON.stringify({
          channel: 'MessagesChannel',
          care_recipient: recipientId,
        }),
      };
      socket.current.send(JSON.stringify(msg));
    },
    [recipientId]
  );

  const onMessage = React.useCallback(
    msg => {
      const sockMessage = JSON.parse(msg.data);
      if (sockMessage.type === 'ping') {
        return;
      }
      if (sockMessage.type === 'welcome') {
        subscribe(socket);
      }
      if (sockMessage.message) {
        const newMessage = sockMessage.message.message;
        socketMessages.current = socketMessages.current.concat([newMessage]);
        setLastMessage(newMessage.id);
        if (newMessage.user.id !== userId) {
          incrementUnreadCount();
        }
      }
    },
    [
      incrementUnreadCount,
      setLastMessage,
      socket,
      socketMessages,
      subscribe,
      userId,
    ]
  );

  return { updateLastRead, socketMessages: socketMessages.current };
}

export default useWebSocket;
