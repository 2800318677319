import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import HoverIcon from './HoverIcon';

const ICON_SIZE = 60;
const WRAPPER_SIZE = 72;

const ImageWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${WRAPPER_SIZE / 2}px;
  display: flex;
  justify-content: center;
  height: ${WRAPPER_SIZE}px;
  position: relative;
  width: ${WRAPPER_SIZE}px;
  ${({ theme }) => theme.shadow}
`;

const StyledObservation = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100px;
  ${({ isButton }) => isButton && `cursor: pointer;`}
  > ${ImageWrapper} .hover-icon {
    opacity: 0;
  }
  :hover > ${ImageWrapper} .hover-icon {
    opacity: 1;
  }
`;

const IconImage = styled.img`
  background-color: ${({ theme }) => theme.colors.blue2};
  border-radius: ${ICON_SIZE / 2}px;
  object-fit: cover;
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
`;

const Name = styled.p`
  color: ${({ theme }) => theme.colors.gray9};
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  margin-top: 7px;
  text-align: center;
`;

function Observation({ hoverIcon, icon_image, onClick, name, ...rest }) {
  return (
    <StyledObservation
      isButton={!!onClick}
      onClick={onClick || undefined}
      {...rest}
    >
      <ImageWrapper>
        <IconImage
          as={icon_image ? 'img' : 'div'}
          src={icon_image || undefined}
        />
        {hoverIcon && <HoverIcon add={hoverIcon === 'add'} />}
      </ImageWrapper>
      <Name>{name}</Name>
    </StyledObservation>
  );
}

Observation.propTypes = {
  hoverIcon: PropTypes.oneOf(['add', 'remove']),
  icon_image: PropTypes.string,
  onClick: PropTypes.func,
  name: PropTypes.string.isRequired,
};

Observation.defaultProps = {
  hoverIcon: null,
  icon_image: null,
};

export default Observation;
