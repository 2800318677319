const theme = {
  // MTS - The higher the number the darker the color
  colors: {
    // other
    green6: '#52C41A',
    red6: '#FB4D3D',

    // blues
    blue2: '#C5D9FC',
    blue6: '#2573F8',
    primary: '#2573F8',

    // grays
    gray2: '#FAFAFA',
    gray4: '#E8E8E8',
    gray6: '#BFBFBF',
    gray7: '#8C8C8C',
    gray8: '#595959',
    gray9: '#262626',

    white: '#fff',
  },

  shadow: 'box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);',
};

export const colors = theme.colors;

export default theme;
