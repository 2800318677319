import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const StyledPageLayout = styled.div`
  display: grid;
  grid-template-rows: 110px 1fr;
  height: 100%;
  max-height: 100vh;
  position: relative;
`;

export const PageHeader = styled.header`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  height: 110px;
  flex: 1;
  padding-left: 24px;
  padding-top: 24px;
`;

export const PageContent = styled.main`
  align-items: stretch;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 24px;
  overflow: auto;
  position: relative;
`;

export const ContentWrapper = styled.div`
  padding: 24px;
`;

function PageLayout({ children, ...rest }) {
  return (
    <StyledPageLayout>
      {/* first child should be the PageHeader */}
      {/* second child should be the PageContent */}
      {children}
    </StyledPageLayout>
  );
}

PageLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

PageLayout.defaultProps = {};

export default PageLayout;
