import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Observation from 'components/Observation';

const StyledIconList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
`;

function IconList({ hoverIcon, icons, selectIcon }) {
  const iconListRef = React.useRef(null);
  const [scrollHeight, setScrollHeight] = React.useState(0);

  React.useEffect(() => {
    setScrollHeight(iconListRef.current.clientHeight);
  }, []);

  return (
    <StyledIconList
      ref={iconListRef}
      style={{
        flex: scrollHeight ? undefined : 1,
        maxHeight: scrollHeight || undefined,
        overflow: 'auto',
      }}
    >
      {scrollHeight !== 0 &&
        icons.map(icon => (
          <Observation
            key={icon.id}
            hoverIcon={hoverIcon}
            onClick={() => selectIcon(icon)}
            {...icon}
          />
        ))}
    </StyledIconList>
  );
}

IconList.propTypes = {
  hoverIcon: PropTypes.oneOf(['add', 'remove']),
  icons: PropTypes.array.isRequired,
  selectIcon: PropTypes.func.isRequired,
};

IconList.defaultProps = {
  hoverIcon: null,
};

export default IconList;
