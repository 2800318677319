// DO NOT EDIT - This file is automatically generated via yarn buildTranslations
// Request access here:
// https://docs.google.com/spreadsheets/d/1HQdC8YZRPFtsn1a427uh3m_hhrbqqYBUdHpct65NwJ0/edit#gid=1763868262

export default {
  common: {
    back: 'Back',
    cancel: 'Cancel',
    close: 'Close',
    date: 'Date',
    email: 'Email',
    goodMorning: 'Good morning',
    goodAfternoon: 'Good afternoon',
    goodEvening: 'Good evening',
    new: 'New',
    remove: 'Remove',
    next: 'Next',
    of: 'of',
    select: 'Select',
    startTyping: 'Start Typing',
    submit: 'Submit',
    time: 'Time',
    trackCare: 'Track Care',
    activities: 'Activities',
    vitals: 'Vitals',
    moodBehavior: 'Mood/Behavior',
    no: 'No',
    yes: 'Yes',
    name: 'Name',
    today: 'Today',
    search: 'Search',
    edit: 'Edit',
    phoneNumber: 'Phone Number',
    permissionError:
      'You do not have the correct permissions to perform that action.',
  },
  login: {
    username: 'Username',
    error: 'Login failed, please try again.',
    password: 'Password',
    language: 'Language',
    login: 'Log In',
    showPassword: 'Show Password',
    title: 'Log in to your Account',
    forgotPassword: 'Forgot Password',
  },
  forgotPassword: {
    error: 'Password reset failed, please try again.',
    success: 'Check your email for password reset instructions.',
    title: 'Password reset',
  },
  nav: {
    dashboard: 'Dashboard',
    careRecipients: 'Care Recipients',
    reporting: 'Reporting',
    admin: 'Admin',
    userManagement: 'User Management',
    interventions: 'Interventions',
    iconTemplates: 'Icon Templates',
    surveys: 'Surveys',
    settings: 'Settings',
    helpContact: 'Help & Contact',
    logout: 'Log Out',
    cm1Text: 'Switch to CM 1.0',
  },
  careRecipients: {
    careRecipients: 'Care Recipients',
    careTeams: 'Care Teams',
    carePlan: 'Care Plan',
    openAlerts: 'Open Alerts',
    lastObservation: 'Last Observation',
    unreadNotes: 'Unread Notes',
  },
  officeCommunication: {
    officeCommunication: 'Office Communication',
    noNotes: 'Create the first note for this recipient below.',
    me: 'Me',
    noteCreationError: 'There was an error creating this note.',
    loadMore: 'Load More',
  },
  iconTemplates: {
    view: 'View',
    viewing: 'Viewing',
    templates: 'Templates',
    by: 'By',
    icons: 'icons',
    created: 'Created',
    copyTemplate: 'Start with this template',
    newTemplate: 'New Blank Template',
    saveNewTemplate: 'Save to Templates',
    newTemplateTitle: 'New Icon Template',
    editTemplateTitle: 'Edit Icon Template',
    tooltipTemplateName: 'Give your new template a name',
    addIcons: 'Add Icons',
    saveTemplate: 'Save Template',
    errorTemplates:
      'An error occurred while retrieving your {{organizationName}} templates',
    errorSavingTemplate: 'An error occurred while saving your template',
    saveTemplateSuccess: 'Template successfully added.',
    updateTemplateSuccess: 'Template successfully updated.',
    noTemplates: "We couldn't find any icon templates for this organization.",
    alertSettings: '{{templateName}} Alert Settings',
    alertTrigger: 'Alert Trigger',
    details: 'Details',
    frequency: 'Frequency',
    urgency: 'Urgency',
    frequencyLabel: 'time(s) in the last',
  },
  familyChat: {
    familyChat: 'Family Chat',
  },
  schedule: {
    schedule: 'Schedule',
    startTime: 'Shift Start Time',
    endTime: 'Shift End Time',
    caregiver: 'Assigned Caregiver',
    noSchedule: 'No active schedule for this day',
  },
  planOfCare: {
    tableTitle: 'End of shift checklist',
    planOfCare: 'Plan of Care',
    newItem: 'Add New Item',
    delete: 'Delete',
    noData: 'Add your first checklist item above',
    newItemText: 'New checklist item',
    saveItem: 'Save Item',
  },
};
