// DO NOT EDIT - This file is automatically generated via yarn buildTranslations
// Request access here:
// https://docs.google.com/spreadsheets/d/1HQdC8YZRPFtsn1a427uh3m_hhrbqqYBUdHpct65NwJ0/edit#gid=1763868262

export default {
  common: {
    back: 'Voltar',
    cancel: 'Cancelar',
    close: 'Fechar',
    email: 'Email',
    goodMorning: 'Bom dia',
    goodAfternoon: 'Boa tarde',
    goodEvening: 'Boa noite',
    new: 'Novo',
    of: 'De / Do / Da',
    submit: 'Enviar',
    trackCare: 'Monitorar os cuidados',
    no: 'Não',
    yes: 'Sim',
  },
  login: {
    error: 'O login falhou, por favor tente novamente',
    password: 'Senha',
    language: 'Idioma',
    login: 'Conecte-se',
    showPassword: 'Mostrar a senha',
  },
  forgotPassword: {},
  nav: {},
  careRecipients: {},
  officeCommunication: {},
  iconTemplates: {},
  familyChat: {},
  schedule: {},
  planOfCare: {},
};
