import React from 'react';
import PropTypes from 'prop-types';
import http from 'utils/http';
import endpoints from 'utils/endpoints';
import useColumns from './useColumns';
import styled from 'styled-components';
import { add, format, isSameDay } from 'date-fns';
import { useTranslation } from 'react-i18next';

import DayPicker from './DayPicker';
import Loading from 'components/Loading';
import { Table } from 'antd';

const HOUR_FORMAT = 'h:mm aaaa';

const StyledSchedule = styled.div`
  padding: 24px;
`;

function Schedule({ recipient }) {
  const [dayIndex, setDayIndex] = React.useState(0);
  const [schedules, setSchedules] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const columns = useColumns();
  const { t } = useTranslation(['schedule']);
  const today = new Date();

  React.useEffect(() => {
    setLoading(true);
    http(
      `${endpoints.schedules}?care_recipient_id=${
        recipient.id
      }&start_date=${format(add(today, { days: -3 }), 'yyyy-MM-dd')}`
    )
      .then(res => setSchedules(res.data.items))
      .catch(err => console.log)
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [recipient.id]);

  function getDataSource() {
    const selectedDay = add(today, { days: dayIndex });
    const selectedDaySchedules = schedules.filter(el =>
      isSameDay(new Date(el.start_at), new Date(selectedDay))
    );
    return selectedDaySchedules.map((el, i) => ({
      caregiver: el.full_name,
      email: el.email,
      endTime: format(new Date(el.end_at), HOUR_FORMAT),
      key: i,
      phoneNumber: el.phone_number,
      startTime: format(new Date(el.start_at), HOUR_FORMAT),
    }));
  }

  const dataSource = getDataSource();

  if (loading) {
    return <Loading />;
  }

  return (
    <StyledSchedule>
      <DayPicker dayIndex={dayIndex} setDayIndex={setDayIndex} />
      <Table
        dataSource={dataSource}
        columns={columns}
        locale={{ emptyText: t('schedule:noSchedule') }}
        pagination={false}
      />
    </StyledSchedule>
  );
}

Schedule.propTypes = {
  recipient: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

Schedule.defaultProps = {};

export default Schedule;
