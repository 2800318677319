import React from 'react';
// import PropTypes from 'prop-types';

// pages
import NotFound from 'components/NotFound';
import RootLayout from 'components/RootLayout';

const Test = () => <div>Reporting</div>;

function Reporting() {
  return (
    <RootLayout>
      <Test path="/" />
      <NotFound default />
    </RootLayout>
  );
}

Reporting.propTypes = {};

Reporting.defaultProps = {};

export default Reporting;
