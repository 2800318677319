import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import PageLayout, { PageContent } from 'components/PageLayout';
import TableTabs from './TableTabs';

import useAlertSettingsState from './useAlertSettingsState';

function IconTemplateAlertSettings({ location }) {
  const { name, selectedIcons, templateId, updatingTemplate } = location.state;
  const { handleIconUpdate, icons, saveTemplate } = useAlertSettingsState(
    templateId,
    name,
    selectedIcons
  );
  return (
    <PageLayout>
      <Header
        previousRoute={location.state.previousRoute}
        onSave={saveTemplate}
        templateName={name}
        updatingTemplate={updatingTemplate}
      />
      <PageContent style={{ padding: 20 }}>
        <TableTabs icons={icons} handleIconUpdate={handleIconUpdate} />
      </PageContent>
    </PageLayout>
  );
}

IconTemplateAlertSettings.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      name: PropTypes.string.isRequired,
      selectedIcons: PropTypes.shape({
        activities: PropTypes.array,
        mood_behavior: PropTypes.array,
        track_care: PropTypes.array,
        vitals: PropTypes.array,
      }),
      templateId: PropTypes.string,
      updatingTemplate: PropTypes.bool.isRequired,
    }),
  }).isRequired,
};

IconTemplateAlertSettings.defaultProps = {};

export default IconTemplateAlertSettings;
