import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  token: null,
  user: {
    first_name: '',
    last_name: '',
  },
  policies: {
    family_member: false,
  },
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authSuccess: (state, authObj) => {
      // Add token to default header in all requests
      axios.defaults.headers[
        'Authorization'
      ] = `Bearer ${authObj.payload.token}`;
      return { ...state, ...authObj.payload };
    },
    logout: () => {
      // Reset the header
      axios.defaults.headers['Authorization'] = '';
      return initialState;
    },
  },
});

export const { authSuccess, logout } = auth.actions;

export default auth.reducer;
