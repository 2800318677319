import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { colors } from 'styles/theme';

import { Divider, Input, Tooltip, Typography } from 'antd';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import Header from './Header';
import IconTabs from '../../components/IconTabs';
import IconList from './IconList';
import PageLayout, { PageContent } from 'components/PageLayout';

import useTemplateState from './useTemplateState';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const StyledPageContent = styled(PageContent)`
  background-color: ${({ theme }) => theme.colors.gray4};
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 4fr 3fr;
`;

const Panel = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const IconsTitleSection = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 4fr 5fr;
`;

function IconTemplatesCreateUpdate({ id, location }) {
  const { t } = useTranslation(['common', 'iconTemplates']);
  const updatingTemplate = !!id;

  const {
    filteredIcons,
    handleIconRemove,
    name,
    search,
    selectIcon,
    selectedIcons,
    setSearch,
    setSelectedCategory,
    setName,
  } = useTemplateState(
    location.state.icons,
    id,
    updatingTemplate && location.state.name
  );

  return (
    <PageLayout>
      <Header
        linkState={{
          name,
          previousRoute: location.pathname,
          selectedIcons,
          templateId: id,
          updatingTemplate,
        }}
        updatingTemplate={updatingTemplate}
      />
      <StyledPageContent>
        <Panel>
          <Tooltip
            arrowPointAtCenter
            color={colors.blue6}
            placement="top"
            title={t('iconTemplates:tooltipTemplateName')}
            visible={name.length === 0}
          >
            <Input
              onChange={e => setName(e.target.value)}
              style={{ maxWidth: 256, marginBottom: 16 }}
              suffix={<EditOutlined style={{ color: colors.blue6 }} />}
              value={name}
            />
          </Tooltip>
          <IconTabs
            hoverIcon="remove"
            icons={selectedIcons}
            onIconClick={handleIconRemove}
            onTabSelect={category => setSelectedCategory(category)}
          />
        </Panel>
        <Panel style={{ maxHeight: '100%' }}>
          <IconsTitleSection>
            <Title level={5} style={{ margin: 0 }}>
              {t('iconTemplates:addIcons')}
            </Title>
            <Input
              onChange={e => setSearch(e.target.value)}
              prefix={<SearchOutlined style={{ color: colors.gray6 }} />}
              placeholder={t('common:search')}
              value={search}
            />
          </IconsTitleSection>
          <Divider />
          <IconList
            hoverIcon="add"
            icons={filteredIcons()}
            selectIcon={selectIcon}
          />
        </Panel>
      </StyledPageContent>
    </PageLayout>
  );
}

IconTemplatesCreateUpdate.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      icons: PropTypes.shape({
        activities: PropTypes.array,
        mood_behavior: PropTypes.array,
        track_care: PropTypes.array,
        vitals: PropTypes.array,
      }),
    }),
  }),
};

IconTemplatesCreateUpdate.defaultProps = {
  location: {
    state: {
      icons: {
        activities: [],
        mood_behavior: [],
        track_care: [],
        vitals: [],
      },
    },
  },
};

export default IconTemplatesCreateUpdate;
