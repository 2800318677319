import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { format } from 'date-fns';

import {
  ArrowRightOutlined,
  CalendarOutlined,
  CloseCircleOutlined,
  EditOutlined,
  FolderOutlined,
} from '@ant-design/icons';
import { Button, Typography } from 'antd';
import ButtonLink from 'components/ButtonLink';
import ImageSection from './ImageSection';

import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconTextWrapper = styled.div`
  margin-bottom: 10px;
`;

const IconText = styled.span`
  margin-left: 10px;
`;

const StyledIconTemplateCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
  box-sizing: border-box;
  border: 2px solid
    ${({ isViewing, theme }) =>
      isViewing ? theme.colors.primary : 'transparent'};
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  width: 247px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
`;

function IconTemplateCard({
  created_at,
  created_by,
  icons,
  id,
  isEditable,
  isViewing,
  onClick,
  name,
}) {
  const { t } = useTranslation(['iconTemplates']);
  const createdByName = `${created_by.first_name} ${created_by.last_name}`;
  const date = format(new Date(created_at), 'MM/dd/yyyy');

  const allIcons = Object.keys(icons).reduce((acc, key) => {
    return acc.concat(icons[key]);
  }, []);

  return (
    <StyledIconTemplateCard isViewing={isViewing}>
      <Top>
        <Title level={4} style={{ marginBottom: 10 }}>
          {name}
        </Title>
        <ImageSection allIcons={allIcons} />
        <IconTextWrapper>
          <FolderOutlined />
          <IconText>
            {allIcons.length} {t('iconTemplates:icons')}
          </IconText>
        </IconTextWrapper>
        <IconTextWrapper>
          <EditOutlined />
          <IconText>
            {t('iconTemplates:by')} {createdByName}
          </IconText>
        </IconTextWrapper>
        <IconTextWrapper>
          <CalendarOutlined />
          <IconText>
            {t('iconTemplates:created')} {date}
          </IconText>
        </IconTextWrapper>
      </Top>
      <ButtonRow>
        {isEditable && (
          <ButtonLink
            state={{ icons, name }}
            to={`/icon-templates/edit/${id}`}
            type={'secondary'}
            style={{ marginTop: 10, marginRight: 8 }}
          >
            {t('common:edit')}
            {<EditOutlined style={{ marginLeft: 8 }} />}
          </ButtonLink>
        )}
        <Button
          type={isViewing ? 'secondary' : 'primary'}
          onClick={onClick}
          style={{ marginTop: 10 }}
        >
          {t(`iconTemplates:${isViewing ? 'viewing' : 'view'}`)}
          {isViewing ? <CloseCircleOutlined /> : <ArrowRightOutlined />}
        </Button>
      </ButtonRow>
    </StyledIconTemplateCard>
  );
}

IconTemplateCard.propTypes = {
  created_at: PropTypes.string.isRequired,
  created_by: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }),
  icons: PropTypes.shape({
    mood_behavior: PropTypes.array,
    track_care: PropTypes.array,
    vitals: PropTypes.array,
  }).isRequired,
  id: PropTypes.number.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isViewing: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

IconTemplateCard.defaultProps = {};

export default IconTemplateCard;
