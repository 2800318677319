import React from 'react';
// import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import RootLayout from 'components/RootLayout';
import RecipientList from './pages/RecipientList';
import SelectedRecipient from './pages/SelectedRecipient';

import usePermission from 'hooks/usePermission';

function CareRecipients() {
  const authorized = usePermission(['org_admin', 'case_manager']);
  if (!authorized) {
    navigate('/');
  }

  return (
    <RootLayout>
      <RecipientList path="/" />
      <SelectedRecipient path="/:recipientId/*" />
    </RootLayout>
  );
}

CareRecipients.propTypes = {};

CareRecipients.defaultProps = {};

export default CareRecipients;
