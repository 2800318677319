import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'styles/theme';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import HeaderWithTabs from 'components/HeaderWithTabs';
import { navigate } from '@reach/router';

import { useTranslation } from 'react-i18next';

function RecipientHeader({ familyChatUnreadCount, pathname, recipient }) {
  const { t } = useTranslation([
    'familyChat',
    'officeCommunication',
    'schedule',
  ]);
  if (!recipient) {
    return null;
  }

  const prefix = `/care-recipients/${recipient.id}`;
  const tabs = [
    {
      active: pathname === prefix,
      text: t('schedule:schedule'),
      to: `${prefix}`,
    },
    {
      active: pathname === `${prefix}/office-communication`,
      text: t('officeCommunication:officeCommunication'),
      to: `${prefix}/office-communication`,
    },
    {
      active: pathname === `${prefix}/family-chat`,
      text: (
        <span>
          {t('familyChat:familyChat')}{' '}
          <Badge
            count={familyChatUnreadCount}
            style={{ marginLeft: 4, marginBottom: 2 }}
          />
        </span>
      ),
      to: `${prefix}/family-chat`,
    },
    {
      active: pathname === `${prefix}/family-chat`,
      text: t('planOfCare:planOfCare'),
      to: `${prefix}/plan-of-care`,
    },
  ];

  const { first_name, last_name } = recipient;

  return (
    <HeaderWithTabs
      pathname={pathname}
      title={
        <div
          role="button"
          onClick={() =>
            navigate(pathname === prefix ? '/care-recipients' : prefix)
          }
          style={{ color: colors.gray9 }}
        >
          <ArrowLeftOutlined style={{ marginRight: 20 }} />
          {first_name} {last_name}
        </div>
      }
      tabs={tabs}
    />
  );
}

RecipientHeader.propTypes = {
  pathname: PropTypes.string.isRequired,
  recipient: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }),
};

RecipientHeader.defaultProps = {};

export default RecipientHeader;
