import React from 'react';
import PropTypes from 'prop-types';
import dedup from './dedup';

import ChatUI from '../../components/ChatUI';
import MessageList from '../../components/ChatUI/MessageList';
import TextEntry from '../../components/ChatUI/TextEntry';

import useChatMessages from './useChatMessages';
import { useTranslation } from 'react-i18next';

function FamilyChat({ clearUnread, recipient, socketMessages, ...rest }) {
  const listRef = React.useRef();
  const { t } = useTranslation(['familyChat']);
  const {
    loading,
    maxPagesReached,
    messages,
    nextPage,
    sendMessage,
    sendingMessage,
  } = useChatMessages(recipient.id);

  // Clear unread count when user leaves this page
  React.useEffect(() => {
    return () => clearUnread();
    // eslint-disable-next-line
  }, []);

  // Scroll to the bottom of the list when we get new socket messages.
  React.useEffect(() => {
    if (socketMessages.length > 0) {
      if (listRef.current && listRef.current.scrollToBottom) {
        listRef.current.scrollToBottom();
      }
    }
  }, [socketMessages.length]);

  const allMessages = dedup(messages.concat(socketMessages)).map(el => ({
    ...el,
    body: el.text,
    user: {
      ...el.user,
      full_name: `${el.user.first_name} ${el.user.last_name}`,
    },
  }));

  const messageList = (
    <MessageList
      incrementPage={maxPagesReached ? null : nextPage}
      loading={loading}
      messages={allMessages}
      ref={listRef}
    />
  );
  const textEntry = (
    <TextEntry loading={sendingMessage} onSendMessage={sendMessage} />
  );

  return (
    <ChatUI
      list={messageList}
      recipient={recipient}
      textEntry={textEntry}
      title={t('familyChat:familyChat')}
    />
  );
}

FamilyChat.propTypes = {
  recipient: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

FamilyChat.defaultProps = {};

export default FamilyChat;
