// DO NOT EDIT - This file is automatically generated via yarn buildTranslations
// Request access here:
// https://docs.google.com/spreadsheets/d/1HQdC8YZRPFtsn1a427uh3m_hhrbqqYBUdHpct65NwJ0/edit#gid=1763868262

export default {
  common: {
    back: '背面',
    cancel: '取消',
    close: '关',
    email: '电子邮件',
    goodMorning: '早上好',
    goodAfternoon: '下午好',
    goodEvening: '晚上好',
    new: '新',
    of: '的',
    submit: '递交',
    trackCare: '跟踪护理',
    no: '没有',
    yes: '是',
  },
  login: {
    error: '登录失败，请再次尝试。',
    password: '密码',
    language: '语言',
    login: '登录',
    showPassword: '显示密码',
  },
  forgotPassword: {},
  nav: {},
  careRecipients: {},
  officeCommunication: {},
  iconTemplates: {},
  familyChat: {},
  schedule: {},
  planOfCare: {},
};
