import { useSelector } from 'react-redux';

function useUnreadNotes(notes) {
  const readNotes = useSelector(state => state.readNotes);
  const userId = useSelector(state => state.auth.user.id);

  function isEqual(a, b) {
    return (
      a.userId === b.userId && a.recipientId === b.recipientId && a.id === b.id
    );
  }

  const unreadNotes = notes
    // first filter out notes that are from this user
    .filter(note => note.user.id !== userId)
    .map(note => ({
      userId: note.user.id,
      recipientId: note.care_recipient_id,
      id: note.id,
    }))
    .filter(note => {
      const isRead = !!readNotes.find(readNote => isEqual(note, readNote));
      return !isRead;
    });
  return unreadNotes;
}

useUnreadNotes.propTypes = {};

useUnreadNotes.defaultProps = {};

export default useUnreadNotes;
