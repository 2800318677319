import { useSelector } from 'react-redux';

function usePermission(can) {
  let roles = useSelector(state => state.auth.policies.user);
  roles = Object.keys(roles).map(role => ({ role, value: roles[role] }));
  if (!Array.isArray(can) || !can.length) {
    console.error('Must pass an array to usePermission hook');
    return false;
  }
  const result = can.find(c =>
    // added true here for better readability
    roles.find(el => el.role === c && el.value === true)
  );
  return !!result;
}

export default usePermission;
