import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';

import { colors } from 'styles/theme';

const IconWrapper = styled.div`
  display: inline;
  position: absolute;
  height: 20px;
  width: 20px;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: opacity 200ms ease-out;
  z-index: 2;
`;

function HoverIcon({ add, ...rest }) {
  const style = {
    style: { color: add ? colors.green6 : colors.red6, fontSize: 20 },
  };
  return (
    <IconWrapper className="hover-icon" {...rest}>
      {add ? <PlusCircleFilled {...style} /> : <MinusCircleFilled {...style} />}
    </IconWrapper>
  );
}

HoverIcon.propTypes = {
  add: PropTypes.bool.isRequired,
};

HoverIcon.defaultProps = {};

export default HoverIcon;
