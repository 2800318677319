import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Typography } from 'antd';

const { Title } = Typography;

const StyledHeader = styled.div`
  align-items: flex-end;
  display: flex;
  overflow: hidden;
  padding-bottom: 10px;
`;

function Header({ children, title }) {
  return (
    <StyledHeader>
      <Title level={4}>{title}</Title>
      {children}
    </StyledHeader>
  );
}

Header.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
};

Header.defaultProps = {
  children: null,
};

export default Header;
