import React from 'react';
import endpoints from 'utils/endpoints';
import http from 'utils/http';

const LIMIT = 20;

function useChatMessages(recipientId) {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [messages, setMessages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [sendingMessage, setSendingMessage] = React.useState(false);
  const [totalPages, setTotalPages] = React.useState(0);

  React.useEffect(() => {
    getMessages();
    // eslint-disable-next-line
  }, []);

  const getMessages = React.useCallback(() => {
    setLoading(true);
    http(endpoints.getMessages, {
      params: {
        care_recipient_id: recipientId,
        limit: LIMIT,
        offset: currentPage * LIMIT,
      },
    })
      .then(({ data }) => {
        setTotalPages(Math.ceil(data.meta.total / LIMIT));
        // MTS - We do this so we can add previous messages to the
        // start of the array if the user clicks the "Load More" button.
        setMessages(data.data.concat(messages));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [currentPage, recipientId, messages, setMessages]);

  function nextPage() {
    setCurrentPage(currentPage + 1);
    getMessages();
  }

  function sendMessage(message) {
    setSendingMessage(true);
    const data = {
      message: {
        care_recipient_id: recipientId,
        text: message,
      },
    };
    http
      .post(endpoints.sendMessage, data)
      .then(res => {
        // not doing anything at the moment
      })
      .catch(console.error)
      .finally(() => setSendingMessage(false));
  }

  return {
    loading,
    maxPagesReached: currentPage === totalPages,
    messages,
    nextPage,
    sendingMessage,
    sendMessage,
  };
}

export default useChatMessages;
