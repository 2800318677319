import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Spin } from 'antd';

const Center = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 40px;
`;

function Loading() {
  return (
    <Center>
      <Spin size="large" />
    </Center>
  );
}

Loading.propTypes = {};

Loading.defaultProps = {};

export default Loading;
