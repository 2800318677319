import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// MTS - 10/24/2020 There is a good chance that this will be changed once permissions are fully fleshed out.
//
function Permission({ can, children }) {
  const role = useSelector(state => state.auth.user.membership_role);
  if (can.indexOf(role) >= 0) {
    return children;
  }
  return null;
}

Permission.propTypes = {
  can: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.any.isRequired,
};

Permission.defaultProps = {};

export default Permission;
