import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const auth = createSlice({
  name: 'readNotes',
  initialState,
  reducers: {
    addReadNotes: (state, action) => {
      return state.concat(action.payload);
    },
  },
});

export const { addReadNotes } = auth.actions;

export default auth.reducer;
