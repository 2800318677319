import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Link as ReachLink } from '@reach/router';
import { Tabs, Typography } from 'antd';

const { TabPane } = Tabs;
const { Title } = Typography;

const StyledHeaderWithTabs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

const TabLink = styled(ReachLink)`
  color: inherit;
  text-decoration: none;
`;

function HeaderWithTabs({ onChange, tabs, title }) {
  function getActiveTab() {
    return tabs.findIndex(tab => tab.active).toString();
  }

  return (
    <StyledHeaderWithTabs>
      <Title level={3} style={{ marginBottom: 9 }}>
        {title}
      </Title>
      <Tabs activeKey={getActiveTab()} defaultActiveKey="0" onChange={onChange}>
        {tabs.map((tab, i) => (
          <TabPane key={i} tab={<TabLink to={tab.to}>{tab.text}</TabLink>} />
        ))}
      </Tabs>
    </StyledHeaderWithTabs>
  );
}

HeaderWithTabs.propTypes = {
  onChange: PropTypes.func,
  title: PropTypes.any.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool.isRequired,
      text: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

HeaderWithTabs.defaultProps = {
  onChange: () => null,
};

export default HeaderWithTabs;
