import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Icon from '../Icon';
import { Link as ReachLink, useLocation } from '@reach/router';
import { Menu } from 'antd';

import { logout } from 'reducers/auth';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import usePermission from 'hooks/usePermission';

import {
  ArrowLeftOutlined,
  // AlertTwoTone,
  // InfoCircleTwoTone,
  // FileTwoTone,
  LogoutOutlined,
  // PieChartTwoTone,
  // SettingTwoTone,
  SmileTwoTone,
} from '@ant-design/icons';

// const { SubMenu } = Menu;

const BrandLink = styled(ReachLink)`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 14px;
`;

const Spacer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

// const Black = styled.span`
//   color: ${({ theme }) => theme.colors.gray9};
// `;

const StyledSideNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

function SideNav() {
  const { t } = useTranslation(['nav']);
  const pathname = useLocation().pathname;
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = React.useState([1]);
  const careRecipientsVisible = usePermission(['org_admin', 'case_manager']);

  const getActiveTab = React.useCallback(() => {
    // Check care-recipients path
    if (pathname.includes('care-recipients')) {
      return setActiveKey(['2']);
    }
    if (pathname.includes('reporting')) {
      return setActiveKey(['3']);
    }
    if (pathname.includes('icon-templates')) {
      return setActiveKey(['6']);
    }
    if (pathname === '/dashboard') {
      return setActiveKey(['1']);
    }
  }, [pathname, setActiveKey]);

  // MTS - We have to do this to work with antd
  React.useEffect(() => {
    getActiveTab();
  }, [getActiveTab, pathname]);

  function handleLogout() {
    dispatch(logout());
  }

  function cm1URL() {
    if (
      window.location.href.includes('staging') ||
      window.location.href.includes('localhost') ||
      window.location.href.includes('netlify')
    ) {
      return 'https://staging.ecaring.com';
    }
    return 'https://secure.ecaring.com';
  }

  return (
    <StyledSideNav>
      <Menu
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        selectedKeys={activeKey}
        style={{ width: 200 }}
        mode="inline"
      >
        <BrandLink to="/dashboard">
          <Icon name="BrandMark" size={30} />
        </BrandLink>
        {/*
        <Menu.Item key="1" icon={<AlertTwoTone />}>
          <ReachLink to="/dashboard">{t('nav:dashboard')}</ReachLink>
        </Menu.Item>
       */}
        <Menu.Item key="11" icon={<ArrowLeftOutlined />}>
          <a href={cm1URL()}>{t('nav:cm1Text')}</a>
        </Menu.Item>
        {careRecipientsVisible && (
          <Menu.Item key="2" icon={<SmileTwoTone />}>
            <ReachLink to="/care-recipients">
              {t('nav:careRecipients')}
            </ReachLink>
          </Menu.Item>
        )}
        {/*
        <Menu.Item key="3" icon={<PieChartTwoTone />}>
          <ReachLink to="/reporting">{t('nav:reporting')}</ReachLink>
        </Menu.Item>
        <SubMenu key="sub1" icon={<FileTwoTone />} title="Admin">
          <Menu.Item key="4">{t('nav:userManagement')}</Menu.Item>
          <Menu.Item key="5">{t('nav:interventions')}</Menu.Item>
          <Menu.Item key="6">
            <ReachLink to="/icon-templates">{t('nav:iconTemplates')}</ReachLink>
          </Menu.Item>
          <Menu.Item key="7">{t('nav:surveys')}</Menu.Item>
        </SubMenu>
        <Menu.Item key="8" icon={<SettingTwoTone />}>
          <Black>{t('nav:settings')}</Black>
        </Menu.Item>
        <Menu.Item key="9" icon={<InfoCircleTwoTone />}>
          <Black>{t('nav:helpContact')}</Black>
        </Menu.Item>
       */}
        <Menu.Item>
          <Spacer />
        </Menu.Item>
      </Menu>
      <Menu onClick={handleLogout} style={{ width: 200 }} mode="inline">
        <Menu.Item key="10" icon={<LogoutOutlined />}>
          {t('nav:logout')}
        </Menu.Item>
      </Menu>
    </StyledSideNav>
  );
}

SideNav.propTypes = {};

SideNav.defaultProps = {};

export default SideNav;
