import React from 'react';
import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { message } from 'antd';
import { navigate } from '@reach/router';
import { sendError } from 'utils/helpers';

import { useTranslation } from 'react-i18next';

// ommiting constraint on purpose here
const DEFAULT_ALERT_SETTING = {
  frequency: 1,
  unit: 'hour',
  urgenct: true,
};

function useAlertSettingsState(id, name, selectedIcons) {
  const { t } = useTranslation(['iconTemplates']);
  const [icons, setIcons] = React.useState(selectedIcons);

  function iconObjectToList(obj) {
    if (!obj) {
      return [];
    }
    return Object.keys(obj).reduce((acc, key) => {
      const theseIcons = obj[key].map(el => ({ ...el, category: key }));
      return acc.concat(theseIcons);
    }, []);
  }

  function saveTemplate() {
    const isUpdate = typeof id !== 'undefined';
    const data = {
      id: id || undefined,
      icons: iconObjectToList(icons).map(el => ({
        alert_setting_attributes: el.alert_setting || undefined,
        id: el.id,
        category: el.category,
      })),
      name,
    };
    // MTS - if we have id then this is a patch, otherwise it is a post
    http[id ? 'patch' : 'post'](endpoints.templateCreateUpdate, data)
      .then(res => {
        message.success(
          t(`iconTemplates:${isUpdate ? 'update' : 'save'}TemplateSuccess`)
        );
        navigate('/icon-templates');
      })
      .catch(err => {
        sendError(
          `Error saving icon template ${isUpdate ? 'update' : 'create'} `,
          err
        );
        message.error(t(`iconTemplates:errorSavingTemplate`));
      });
  }

  function handleIconUpdate({ icon, value, key }) {
    const newIcons = { ...icons };
    const newCat = newIcons[icon.category].map((el, i) => {
      if (el.id === icon.id) {
        // This checks to see if the user is removing the alert
        if (key === 'constraint' && value === 'no alert') {
          return {
            ...icon,
            alert_setting: undefined,
          };
        }
        return {
          ...icon,
          alert_setting: {
            // This ensures that we always have default values and prevents
            // errors from the backend.
            ...DEFAULT_ALERT_SETTING,
            ...icon.alert_setting,
            [key]: value,
          },
        };
      }
      return el;
    });
    newIcons[icon.category] = newCat;
    setIcons(newIcons);
  }
  return { handleIconUpdate, icons, saveTemplate };
}

export default useAlertSettingsState;
