import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en from "./en.js";
import es from "./es.js";
import fil from "./fil.js";
import fr from "./fr.js";
import pt from "./pt.js";
import ru from "./ru.js";
import zh_CN from "./zh_CN.js";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",

    // add languages here
    resources: {
      en,
      es,
      fil,
      fr,
      pt,
      ru,
      zh_CN,
    },

    debug: true,

    cache: {
      enabled: true,
    },

    interpolation: {
      escapeValue: false, // not needed for react as it does escape per default to prevent xss!
    },

    react: {
      wait: true,
    },
  });

export default i18n;
