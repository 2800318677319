import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from './components';

function Icon({ name, ...rest }) {
  const IconComponent = Icons[name];
  return <IconComponent {...rest} />;
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
