import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { isToday, format } from 'date-fns';

import { Typography } from 'antd';

import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const StyledText = styled(Text)`
  font-size: 12px;
`;

const Name = styled.span`
  color: ${({ theme }) => theme.colors.gray8};
`;

const Time = styled.span`
  color: ${({ theme }) => theme.colors.gray7};
  margin-left: 8px;
`;

function NameTime({ name, timestamp }) {
  const { t } = useTranslation(['common']);
  const day = isToday(new Date(timestamp))
    ? t('common:today')
    : format(new Date(timestamp), 'LLL dd, yyyy');
  const time = format(new Date(timestamp), 'h:mm aaaa');
  return (
    <StyledText>
      <Name>{name}</Name>{' '}
      <Time>
        {day} {time}
      </Time>
    </StyledText>
  );
}

NameTime.propTypes = {
  name: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

NameTime.defaultProps = {};

export default NameTime;
