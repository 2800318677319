import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { parseHtml } from 'utils/helpers';

import { Avatar, Typography } from 'antd';
import NameTime from './NameTime';
import { UserOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const StyledMessage = styled.div`
  display: flex;
  justify-content: ${({ self }) => (self ? 'flex-end' : 'flex-start')};
  flex-direction: row;
  margin: 10px 0;
`;

const MessageItem = styled.div`
  background-color: ${({ theme }) => theme.colors.gray2};
  display: flex;
  padding: 24px;
  flex-direction: ${({ self }) => (self ? 'row-reverse' : 'row')};
  max-width: 65%;
`;

const MessageBody = styled.div``;

const StyledAvatar = styled(({ self, ...rest }) => <Avatar {...rest} />)`
  ${({ self }) => (self ? 'margin-left: 11px;' : 'margin-right: 11px;')}
`;

const Image = styled.img`
  height: 265px;
  object-fit: cover;
`;

const Audio = styled.audio``;

function Message({
  attachment,
  attachment_type,
  body,
  created_at,
  self,
  user,
  ...rest
}) {
  const { t } = useTranslation(['officeCommunication']);
  const parsedBody = parseHtml(body);
  const isAudio = attachment_type === 'audio' || attachment_type === 'video';
  return (
    <StyledMessage data-testid="message" self={self}>
      <MessageItem self={self}>
        <StyledAvatar
          icon={<UserOutlined />}
          self={self}
          style={{ minWidth: 32 }}
        />
        <MessageBody>
          <NameTime
            name={self ? t('officeCommunication:me') : user.full_name}
            timestamp={created_at}
          />
          <div>
            <Text data-testid="message-body">{parsedBody}</Text>
          </div>
          {attachment_type === 'image' && (
            <Image alt={parsedBody} src={attachment} />
          )}
          {isAudio && (
            <Audio controls>
              <source src={attachment} type="audio/mp4" />
            </Audio>
          )}
        </MessageBody>
      </MessageItem>
    </StyledMessage>
  );
}

Message.propTypes = {
  attachment: PropTypes.string,
  attachment_type: PropTypes.string,
  body: PropTypes.string,
  care_recipient_id: PropTypes.number.isRequired,
  created_at: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  self: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    full_name: PropTypes.string.isRequired,
  }).isRequired,
};

Message.defaultProps = {
  attachment: null,
  attachment_type: null,
  body: null,
};

export default Message;
