import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Message from './Message';
import { List } from 'antd';
import LoadMore from './LoadMore';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const StyledList = styled(List)`
  .ant-list-footer {
    padding: 0;
    height: 0;
  }
`;

const MessageList = React.forwardRef(
  ({ incrementPage, loading, messages, ...rest }, ref) => {
    const { t } = useTranslation(['officeCommunication']);
    const userId = useSelector(state => state.auth.user.id);

    const footerRef = React.useRef(null);
    const [scrolledToBottom, setScrolledToBottom] = React.useState(false);

    // Scroll to bottom once notes are loaded
    React.useEffect(() => {
      if (messages.length && !scrolledToBottom) {
        setScrolledToBottom(true);
        footerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, [scrolledToBottom, messages]);

    // MTS - Allows us to scroll to bottom of list from a parent. Kind of hacky.
    // eslint-disable-next-line
    React.useImperativeHandle(ref, () => ({
      scrollToBottom() {
        footerRef.current.scrollIntoView({ behavior: 'smooth' });
      },
    }));

    function renderItem(item) {
      return <Message self={item.user.id === userId} {...item} />;
    }

    const sortedMessages = messages.sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );

    return (
      <StyledList
        dataSource={sortedMessages}
        footer={<div ref={footerRef} />}
        header={
          incrementPage && (
            <LoadMore loading={loading} onClick={incrementPage} />
          )
        }
        itemLayout="vertical"
        loading={loading}
        locale={{
          emptyText: t('officeCommunication:noNotes'),
        }}
        renderItem={renderItem}
        rowKey={'id'}
        style={{
          flex: '1 1 auto',
          overflowY: 'auto',
          minHeight: 1,
        }}
        {...rest}
      />
    );
  }
);

MessageList.propTypes = {
  incrementPage: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  messages: PropTypes.array.isRequired,
};

MessageList.defaultProps = {
  incrementPage: null,
};

export default React.memo(MessageList);
