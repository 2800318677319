import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, Table, Typography } from 'antd';
import Delete from './Delete';
import NewItem from './NewItem';
import { PlusCircleOutlined } from '@ant-design/icons';

import useChecklist from './useChecklist';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const Container = styled.div`
  padding: 24px;
  tr td:first-of-type {
    width: 100%;
  }
  tr td:last-of-type {
    display: flex;
    justify-content: flex-end;
  }
`;

const Top = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

function PlanOfCare({ recipient }) {
  const { t } = useTranslation(['planOfCare']);
  const [adding, setAdding] = React.useState(false);
  const { addItem, checklist, removeItem, saving } = useChecklist(recipient.id);
  const dataSource = checklist.map((el, i) => ({
    title: el,
    index: i,
    action: null,
  }));
  const columns = [
    {
      title: t('planOfCare:tableTitle'),
      dataIndex: 'title',
      render: (text, data) => text,
    },
    {
      title: null,
      dataIndex: 'action',
      render: (text, data) => (
        <Delete onDelete={() => removeItem(data.index)} />
      ),
    },
  ];

  function handleSave(item) {
    setAdding(false);
    addItem(item);
  }
  return (
    <Container>
      <Top>
        <Title level={4} style={{ margin: 0, padding: 0 }}>
          {t('planOfCare:planOfCare')}
        </Title>
        <Button onClick={() => setAdding(true)} loading={saving}>
          {!saving && (
            <>
              <PlusCircleOutlined style={{ marginRight: 8 }} />
              {t('planOfCare:newItem')}
            </>
          )}
        </Button>
      </Top>
      {adding && (
        <NewItem onCancel={() => setAdding(false)} onSave={handleSave} />
      )}
      <Table
        dataSource={dataSource}
        columns={columns}
        locale={{ emptyText: t('planOfCare:noData') }}
        pagination={false}
      />
    </Container>
  );
}

PlanOfCare.propTypes = {
  recipient: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

PlanOfCare.defaultProps = {};

export default PlanOfCare;
