import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Router } from '@reach/router';

import SideNav from '../SideNav';
import NotFound from '../NotFound';

const Layout = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
`;

const Right = styled.div`
  background-color: ${({ theme }) => theme.colors.gray4};
  min-height: 100vh;
`;

const StyledRouter = styled(Router)`
  height: 100vh;
`;

function RootLayout({ children }) {
  return (
    <Layout>
      <SideNav />
      <Right>
        <StyledRouter>
          {children}
          <NotFound default />
        </StyledRouter>
      </Right>
    </Layout>
  );
}

RootLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

RootLayout.defaultProps = {};

export default RootLayout;
