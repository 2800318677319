import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Button, Input } from 'antd';

import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

// MTS - This is just a div because pressing enter inside of a text
// area goes to the next line instead of submitting a form.
const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  margin-top: 12px;
`;

function MessageInput({ loading, onSendMessage }) {
  const [text, setText] = React.useState('');
  const { t } = useTranslation(['common', 'officeCommunication']);

  function onSubmit() {
    onSendMessage(text);
    setText('');
  }

  return (
    <Form>
      <TextArea rows={4} onChange={e => setText(e.target.value)} value={text} />
      <ButtonWrapper>
        <Button
          disabled={text.length === 0}
          loading={loading}
          onClick={onSubmit}
          type="primary"
        >
          {t('common:submit')}
        </Button>
      </ButtonWrapper>
    </Form>
  );
}

MessageInput.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSendMessage: PropTypes.func.isRequired,
};

MessageInput.defaultProps = {};

export default MessageInput;
