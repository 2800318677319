import React from 'react';
import PropTypes from 'prop-types';
import { add, format } from 'date-fns';
import styled from 'styled-components';

import { Button, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const { Title } = Typography;

const StyledDayPicker = styled.div`
  align-items: center;
  display: flex;
  padding-bottom: 24px;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  align-items: center;
  border-radius: 2px;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;
`;

const ButtonGroup = styled.div`
  display: flex;
`;

function DayPicker({ dayIndex, setDayIndex }) {
  const date = add(new Date(), { days: dayIndex });
  return (
    <StyledDayPicker>
      <Title level={4} style={{ margin: 0, marginRight: 16, padding: 0 }}>
        {format(date, 'EEEE, LLLL d')}
      </Title>
      <ButtonGroup>
        <StyledButton
          disabled={dayIndex === -3}
          onClick={() => setDayIndex(dayIndex - 1)}
          style={{ marginRight: 8 }}
        >
          <LeftOutlined />
        </StyledButton>
        <StyledButton
          disabled={dayIndex === 6}
          onClick={() => setDayIndex(dayIndex + 1)}
        >
          <RightOutlined />
        </StyledButton>
      </ButtonGroup>
    </StyledDayPicker>
  );
}

DayPicker.propTypes = {
  dayIndex: PropTypes.number.isRequired,
  setDayIndex: PropTypes.func.isRequired,
};

DayPicker.defaultProps = {};

export default DayPicker;
