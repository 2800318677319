import React from "react";
import styled from "styled-components";
import endpoints from "utils/endpoints";
import http from "utils/http";

import { Button, Input, message, Space, Typography } from "antd";

import { useTranslation } from "react-i18next";

const { Title } = Typography;

const Layout = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray4};
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

const Content = styled.form`
  align-items: stretch;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  width: 330px;
  padding: 24px;
`;

function Login() {
  const { t } = useTranslation(["forgotPassword"]);
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    http
      .post(endpoints.passwordReset, { email })
      .then(({ data }) => {
        setEmail("");
        message.success(t("forgotPassword:success"));
      })
      .catch((err) => {
        message.error(t("forgotPassword:error"));
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <Layout>
      <Content onSubmit={handleSubmit}>
        <Space direction="vertical" size={24}>
          <Title level={3} style={{ textAlign: "center", marginBottom: 0 }}>
            {t("forgotPassword:title")}
          </Title>
          <Input
            htmlType="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t("common:email")}
            required
            size="large"
            value={email}
          />
          <Button
            block
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
          >
            {t("common:submit")}
          </Button>
        </Space>
      </Content>
    </Layout>
  );
}

export default Login;
