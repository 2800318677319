import React from 'react';
import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { sendError } from 'utils/helpers';

import { message } from 'antd';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function useTabsAndTemplates() {
  const { t } = useTranslation(['iconTemplates']);
  const organizationId = useSelector(state => state.auth.user.organization_id);
  const organizationName = useSelector(
    state => state.auth.user.organization_name
  );
  const [selectedTemplateId, setSelectedTemplateId] = React.useState(null);

  const [defaultLoading, setDefaultLoading] = React.useState(true);
  const [orgLoading, setOrgLoading] = React.useState(false);

  const [defaultTemplates, setDefaultTemplates] = React.useState([]);
  const [orgTemplates, setOrgTemplates] = React.useState([]);

  const tTemplates = t('iconTemplates:templates');

  const [tabs, setTabs] = React.useState([
    {
      active: true,
      text: `eCaring ${tTemplates}`,
      to: '/icon-templates',
    },
  ]);

  React.useEffect(() => {
    setDefaultLoading(true);
    if (organizationId) {
      getOrgTemplates();
    }
    http(endpoints.getIconTemplates)
      .then(res => {
        setDefaultTemplates(res.data.data);
      })
      .catch(err => {
        message.error(
          t('iconTemplates:errorTemplates', { organizationName: 'eCaring' })
        );
        sendError('Error fetching default templates', err);
      })
      .finally(() => setDefaultLoading(false));
    // eslint-disable-next-line
  }, [organizationId]);

  function getOrgTemplates() {
    setOrgLoading(true);
    http(endpoints.getIconTemplates, {
      params: { organization_id: organizationId },
    })
      .then(res => {
        setOrgTemplates(res.data.data);
        setTabs(
          tabs.concat({
            active: false,
            text: `${organizationName} ${tTemplates}`,
            to: '/icon-templates',
          })
        );
      })
      .catch(err => {
        sendError('Error fetching organization templates', err);
        message.error(t('iconTemplates:errorTemplates', { organizationName }));
      })
      .finally(() => setOrgLoading(false));
  }

  function handleCardClick(id) {
    // close the detail view
    if (selectedTemplateId === id) {
      return setSelectedTemplateId(null);
    }
    setSelectedTemplateId(id);
  }

  function onTabChange(tab) {
    setSelectedTemplateId(null);
    const newActive = parseInt(tab, 10);
    setTabs(tabs.map((t, i) => ({ ...t, active: newActive === i })));
  }

  const activeTab = tabs.findIndex(t => t.active);

  return {
    activeTab,
    handleCardClick,
    loading: defaultLoading || orgLoading,
    organizationId,
    onTabChange,
    selectedTemplateId,
    tabs,
    templates: activeTab === 0 ? defaultTemplates : orgTemplates,
  };
}

useTabsAndTemplates.propTypes = {};

useTabsAndTemplates.defaultProps = {};

export default useTabsAndTemplates;
