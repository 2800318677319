import React from 'react';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';
import PageLayout, { PageHeader, PageContent } from 'components/PageLayout';
import RecipientHeader from './components/RecipientHeader';
import { Router } from '@reach/router';
import { message } from 'antd';

import http from 'utils/http';
import endpoints from 'utils/endpoints';
import { sendError } from 'utils/helpers';

import useWebSocket from './useWebSocket';

// pages
import FamilyChat from './pages/FamilyChat';
import PlanOfCare from './pages/PlanOfCare';
import OfficeCommunication from './pages/OfficeCommunication';
import Schedule from './pages/Schedule';

function SelectedRecipient({ recipientId, location, ...rest }) {
  const [recipient, setRecipient] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [unreadCount, setUnreadCount] = React.useState(0);

  const incrementUnreadCount = () => {
    setUnreadCount(unreadCount + 1);
  };

  const { updateLastRead, socketMessages } = useWebSocket(
    recipientId,
    incrementUnreadCount
  );

  const shouldFetch = !loading && (!recipient || recipient.id !== recipientId);
  React.useEffect(() => {
    // fetch data when recipient has changed or is null
    if (shouldFetch) {
      fetchRecipient();
      fetchUnreadMessages();
    }
    // eslint-disable-next-line
  }, []);

  const fetchUnreadMessages = React.useCallback(() => {
    http
      .get(endpoints.unreadMessages, {
        params: { care_recipient_id: recipientId },
      })
      .then(res => {
        setUnreadCount(res.data);
      })
      .catch(err => {
        sendError('Unable to retrieve unread family chat msgs', err);
      });
  }, [recipientId]);

  const fetchRecipient = React.useCallback(() => {
    setLoading(true);
    http
      .get(endpoints.careRecipientDetails, {
        params: { care_recipient_id: recipientId },
      })
      .then(res => {
        setRecipient(res.data);
      })
      .catch(err => {
        message.error('Unable to fetch recipient');
      })
      .finally(() => setLoading(false));
  }, [recipientId]);

  function clearUnread() {
    updateLastRead();
    setUnreadCount(0);
  }

  const isLoading = loading || !recipient || !recipientId;
  return (
    <PageLayout>
      <PageHeader>
        {loading ? (
          <Loading />
        ) : (
          <RecipientHeader
            familyChatUnreadCount={unreadCount}
            recipient={recipient}
            pathname={location.pathname}
          />
        )}
      </PageHeader>
      <PageContent>
        {isLoading && <Loading />}
        {!isLoading && (
          <Router style={{ flex: 1, maxHeight: '100%' }}>
            <Schedule path="/" recipient={recipient} />
            <OfficeCommunication
              path="/office-communication"
              recipient={recipient}
            />
            <FamilyChat
              clearUnread={clearUnread}
              path="/family-chat"
              recipient={recipient}
              socketMessages={socketMessages}
            />
            <PlanOfCare path="/plan-of-care" recipient={recipient} />
          </Router>
        )}
      </PageContent>
    </PageLayout>
  );
}

SelectedRecipient.propTypes = {
  recipientId: PropTypes.string,
};

SelectedRecipient.defaultProps = {
  recipientId: null,
};

export default SelectedRecipient;
