import React from 'react';
import PropTypes from 'prop-types';

import { Select } from 'antd';

const { Option } = Select;

function DetailsCell({ handleIconUpdate, icon }) {
  const hasAlertSetting = !!icon.alert_setting;
  const options = [
    'no alert',
    'is less than',
    'is greater than',
    'is reported',
    'is not reported',
  ];
  return (
    <Select
      onChange={value => handleIconUpdate({ icon, value, key: 'constraint' })}
      style={{ minWidth: 150 }}
      value={hasAlertSetting ? icon.alert_setting.constraint : options[0]}
    >
      {options.map((option, i) => (
        <Option key={i} value={option}>
          {option}
        </Option>
      ))}
    </Select>
  );
}

DetailsCell.propTypes = {
  handleIconUpdate: PropTypes.func.isRequired,
  icon: PropTypes.any.isRequired,
};

DetailsCell.defaultProps = {};

export default DetailsCell;
