import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { colors } from 'styles/theme';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import ButtonLink from 'components/ButtonLink';
import { Link as ReachLink } from '@reach/router';
import { PageHeader } from 'components/PageLayout';

import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const StyledHeader = styled(PageHeader)`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding-bottom: 18px;
`;

const ButtonArea = styled.div`
  margin-right: 24px;
  text-transfrom: capitalize;
`;

function Header({ linkState, onSave, updatingTemplate }) {
  const { t } = useTranslation(['common', 'iconTemplates']);

  return (
    <StyledHeader>
      <Title level={3} style={{ marginBottom: 0 }}>
        <ReachLink to="/icon-templates" style={{ color: colors.gray9 }}>
          <ArrowLeftOutlined style={{ marginRight: 20 }} />
          {t(`iconTemplates:${updatingTemplate ? 'edit' : 'new'}TemplateTitle`)}
        </ReachLink>
      </Title>
      <ButtonArea>
        <ButtonLink
          type="default"
          style={{ marginRight: 8 }}
          to={'/icon-templates'}
        >
          {t('common:cancel')}
        </ButtonLink>
        <ButtonLink type="primary" state={linkState} to="alert-settings">
          {t('common:next')}
        </ButtonLink>
      </ButtonArea>
    </StyledHeader>
  );
}

Header.propTypes = {
  linkState: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  updatingTemplate: PropTypes.bool.isRequired,
};

Header.defaultProps = {
  linkState: {},
};

export default Header;
