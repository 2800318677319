import React from 'react';

import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { useSelector } from 'react-redux';

const PER_PAGE = 20;

function useGetNotes(recipientId) {
  const [maxPagesReached, setMaxPagesReached] = React.useState(false);
  const [initialLoading, setInitialLoading] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [notes, setNotes] = React.useState([]);
  const token = useSelector(state => state.auth.token);

  React.useEffect(() => {
    if (token) {
      fetchNotes();
    }
    // eslint-disable-next-line
  }, [page, token]);

  const fetchNotes = React.useCallback(() => {
    if (loading || !token) {
      return;
    }
    setLoading(true);
    const params = {
      care_recipient_id: recipientId,
      page,
      page_size: PER_PAGE,
      token,
    };
    http(endpoints.getNotes, {
      params,
    })
      .then(res => {
        if (res.data.length !== 20) {
          setMaxPagesReached(true);
        }
        setNotes(res.data.concat(notes));
      })
      .catch(err => console.log({ err }))
      .finally(() => {
        setInitialLoading(false);
        setLoading(false);
      });
  }, [loading, notes, page, recipientId, token]);

  function incrementPage() {
    setPage(page + 1);
  }

  // This gets called when a user submits a note
  function addNote(note) {
    setNotes([note].concat(notes));
  }

  return {
    addNote,
    incrementPage,
    initialLoading,
    loading,
    maxPagesReached,
    notes,
  };
}

useGetNotes.propTypes = {};

useGetNotes.defaultProps = {};

export default useGetNotes;
