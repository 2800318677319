import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Avatar } from 'antd';

const ICON_SIZE = 32;

const IconImage = styled(Avatar)`
  position: absolute;
  top: 0;
  left: ${({ index }) => index * (ICON_SIZE / 2)}px;
  ${({ theme }) => theme.shadow}
`;

const ImageWrapper = styled.div`
  position: relative;
  height: ${ICON_SIZE}px;
  margin-bottom: 12px;
`;

const iconImages = [
  'https://d9mw4eszeoq0m.cloudfront.net/staging/category_icon_node_images/images/000/000/430/original/activities.png?1568922097',
  'https://d9mw4eszeoq0m.cloudfront.net/staging/observation_icon_node_images/images/000/000/356/original/bath.png?1568922071',
  'https://d9mw4eszeoq0m.cloudfront.net/staging/observation_icon_node_images/images/000/000/455/original/bed_bath.png?1568922104',
  'https://d9mw4eszeoq0m.cloudfront.net/staging/observation_icon_node_images/images/000/000/426/original/brush_hair.png?1568922096',
  'https://d9mw4eszeoq0m.cloudfront.net/staging/observation_icon_node_images/images/000/000/544/original/brush_teeth.png?1568922131',
  'https://d9mw4eszeoq0m.cloudfront.net/staging/observation_icon_node_images/images/000/000/544/original/brush_teeth.png?1568922131',
  'https://d9mw4eszeoq0m.cloudfront.net/staging/observation_icon_node_images/images/000/000/544/original/brush_teeth.png?1568922131',
  'https://d9mw4eszeoq0m.cloudfront.net/staging/observation_icon_node_images/images/000/000/544/original/brush_teeth.png?1568922131',
];

function ImageSection({ allIcons }) {
  const maxIcons = allIcons.slice(0, 5);
  const extraIcons = allIcons.length > 5 ? allIcons.length - 5 : null;

  return (
    <ImageWrapper>
      {maxIcons.map((icon, i) => (
        <IconImage key={i} index={i} src={iconImages[i]} />
      ))}
      {extraIcons && (
        <Avatar
          key="additional"
          style={{
            border: 0,
            color: '#0845b2',
            backgroundColor: '#c5d9fc',
            left: 5 * (ICON_SIZE / 2),
            position: 'absolute',
          }}
        >
          +{extraIcons}
        </Avatar>
      )}
    </ImageWrapper>
  );
}

ImageSection.propTypes = {
  allIcons: PropTypes.array,
};

ImageSection.defaultProps = {
  allIcons: [],
};

export default ImageSection;
