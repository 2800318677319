import { XmlEntities as entities } from 'html-entities';

// MTS - We should use this function to handle all errors. Right now
// it doesn't do anything but we'll hook it up to our error monitoring
// solution once we have one in place.
export const sendError = (message, errObj, extraData) => {
  function getResponse() {
    if (errObj && errObj.response && errObj.response.data) {
      return JSON.stringify(errObj.response.data);
    }
    return 'no backend message';
  }

  if (process.env.NODE_ENV === 'production' && window.airbrake) {
    window.airbrake.notify({
      context: {
        backendMessage: getResponse(),
        message,
        extraData,
      },
      error: errObj,
    });
  } else {
    console.log({ message, errObj, extraData, backendMessage: getResponse() });
  }
};

export const parseHtml = text => {
  if (!text) {
    return text;
  }
  // Remove html tags
  let parsed = text.replace(/<\/?[^>]+(>|$)/g, '');
  // Remove html entities
  parsed = entities.decode(parsed);
  return parsed;
};
