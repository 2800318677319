import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

// Reducers
import auth from './auth';
import readNotes from './readNotes';

const appReducer = combineReducers({
  auth,
  readNotes,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    // This reinitializes every reducer
    const initialState = appReducer(undefined, action);
    // Now add back the notes
    return { ...initialState, readNotes: state.readNotes };
  }

  return appReducer(state, action);
};

const suffix = process.env.NODE_ENV;

const persistConfig = {
  key: `eCaring CM 2.0 - ${suffix}`,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: {
    name: `eCaring CM 2.0 - ${suffix}`,
  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
  ],
});

// Hot reloading
// if (process.env.NODE_ENV !== 'production' && module.hot) {
//   // module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
//   module.hot.accept('./', () => store.replaceReducer(rootReducer));
// }
let persistor = persistStore(store);

export default {
  persistor,
  store,
};
