import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from 'antd';

import { useTranslation } from 'react-i18next';
import { colors } from 'styles/theme';

const Wrapper = styled.div`
  display: flex;
`;

function Delete({ onDelete }) {
  const { t } = useTranslation(['planOfCare']);
  const [confirming, setConfirming] = React.useState(false);

  function handleDelete() {
    setConfirming(false);
    onDelete();
  }

  if (confirming) {
    return (
      <Wrapper>
        <Button danger onClick={handleDelete} style={{ marginRight: 8 }}>
          {t('planOfCare:delete')}
        </Button>
        <Button onClick={() => setConfirming(false)}>
          {t('common:cancel')}
        </Button>
      </Wrapper>
    );
  }
  return (
    <Button
      onClick={() => setConfirming(true)}
      style={{
        background: 'transparent',
        border: 0,
        boxShadow: 'none',
        textTransform: 'capitalize',
      }}
    >
      <span style={{ color: colors.blue }}>{t('planOfCare:delete')}</span>
    </Button>
  );
}

Delete.propTypes = {
  onDelete: PropTypes.func.isRequired,
};

Delete.defaultProps = {};

export default Delete;
