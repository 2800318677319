import React from 'react';
import PropTypes from 'prop-types';

import { Table, Tabs } from 'antd';

import useColumns from './useColumns';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;

function IconTabs({ handleIconUpdate, icons, ...rest }) {
  const [activeTab, setActiveTab] = React.useState(null);
  const { t } = useTranslation(['common', 'iconTemplates']);
  const columns = useColumns(handleIconUpdate);

  const tabs = [
    { display: t('common:trackCare'), key: 'track_care' },
    { display: t('common:activities'), key: 'activities' },
    { display: t('common:vitals'), key: 'vitals' },
    { display: t('common:moodBehavior'), key: 'mood_behavior' },
  ];

  React.useEffect(() => {
    setActiveTab(tabs[0].key);
    // eslint-disable-next-line
  }, []);

  function handleTabChange(i) {
    const index = parseInt(i, 10);
    setActiveTab(tabs[index].key);
  }

  function getIconsFromKey(key) {
    return icons[key] || [];
  }

  const currentTabIcons = getIconsFromKey(activeTab).map(icon => ({
    key: icon.id,
    ...icon,
  }));

  return (
    <Tabs defaultActiveKey="0" size="small" onChange={handleTabChange}>
      {tabs.map((tab, i) => (
        <TabPane
          key={i}
          tab={`${tab.display} (${getIconsFromKey(tab.key).length})`}
        >
          <Table
            dataSource={currentTabIcons}
            columns={columns}
            pagination={false}
            rowClassName="alert-settings-row"
          />
        </TabPane>
      ))}
    </Tabs>
  );
}

IconTabs.propTypes = {
  icons: PropTypes.any.isRequired,
};

IconTabs.defaultProps = {
  hoverIcon: null,
};

export default IconTabs;
