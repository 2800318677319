// DO NOT EDIT - This file is automatically generated via yarn buildTranslations
// Request access here:
// https://docs.google.com/spreadsheets/d/1HQdC8YZRPFtsn1a427uh3m_hhrbqqYBUdHpct65NwJ0/edit#gid=1763868262

export default {
  common: {
    back: 'Balik',
    cancel: 'Kansela',
    close: 'Isara',
    email: 'Email',
    goodMorning: 'Magandang umaga',
    goodAfternoon: 'Magandang hapon',
    goodEvening: 'Magandang gabi',
    new: 'Bago',
    of: 'ng',
    submit: 'i-submitv',
    trackCare: 'Track care',
    no: 'Hindi',
    yes: 'Oo',
  },
  login: {
    error: 'Login di pumasok, Paki-ulit',
    password: 'Password',
    language: 'Wika',
    login: 'Maglog in',
    showPassword: 'pakita ang Password',
  },
  forgotPassword: {},
  nav: {},
  careRecipients: {},
  officeCommunication: {},
  iconTemplates: {},
  familyChat: {},
  schedule: {},
  planOfCare: {},
};
