import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import ButtonLink from 'components/ButtonLink';
import { Empty } from 'antd';
import HeaderWithTabs from 'components/HeaderWithTabs';
import IconTemplateCard from '../../components/IconTemplateCard';
import IconTemplateViewing from './IconTemplateViewing';
import Loading from 'components/Loading';
import PageLayout, { PageHeader, PageContent } from 'components/PageLayout';
import { PlusCircleOutlined } from '@ant-design/icons';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useTabsAndTemplates from './useTabsAndTemplates';

const StyledPageContent = styled(PageContent)`
  background-color: ${({ theme }) => theme.colors.gray4};
  display: grid;
  grid-template-columns: ${({ isViewing }) => (isViewing ? '1fr 1fr' : '1fr')};
  grid-gap: 0 16px;
  max-height: 100%;
  overflow: hidden;
`;

const StyledPageHeader = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
  padding-right: 24px;
`;

const CardWrapper = styled.div`
  align-content: flex-start;
  display: grid;
  grid-template-columns: repeat(auto-fill, 247px);
  grid-gap: 8px 8px;
  max-height: 100%;
  overflow: auto;
`;

const EmptyWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
  padding: 24px;
  ${({ theme }) => theme.shadow}
`;

function IconTemplatesRoot() {
  const { t } = useTranslation(['iconTemplates', 'nav']);
  const policies = useSelector(state => state.auth.policies);
  const {
    activeTab,
    handleCardClick,
    loading,
    organizationId,
    onTabChange,
    selectedTemplateId,
    tabs,
    templates,
  } = useTabsAndTemplates();

  const isViewing = !!selectedTemplateId;

  function sortedTemplates() {
    if (!isViewing) {
      return templates;
    }
    // Creates a new array with slice then moves the selected template to the first position
    return templates
      .slice(0)
      .sort(
        (a, b) => (b.id === selectedTemplateId) - (a.id === selectedTemplateId)
      );
  }

  function isEditable(template) {
    // We may need to change this in the future depending on if platform admins have the
    // ability to edit any org template.
    if (activeTab === 0) {
      return policies.user.platform_admin;
    }
    return (
      template.organization_id !== null &&
      template.organization_id === organizationId
    );
  }

  return (
    <PageLayout>
      <StyledPageHeader>
        <HeaderWithTabs
          onChange={onTabChange}
          tabs={tabs}
          title={t('nav:iconTemplates')}
        />
        <ButtonLink
          style={{ alignSelf: 'flex-end', marginBottom: 16 }}
          to="new"
        >
          <PlusCircleOutlined style={{ marginRight: 8 }} />
          {t('iconTemplates:newTemplate')}
        </ButtonLink>
      </StyledPageHeader>
      <StyledPageContent isViewing={isViewing}>
        {loading && <Loading />}
        <CardWrapper>
          {!loading &&
            sortedTemplates().map(template => (
              <IconTemplateCard
                isEditable={isEditable(template)}
                isViewing={template.id === selectedTemplateId}
                key={template.id}
                onClick={() => handleCardClick(template.id)}
                {...template}
              />
            ))}
          {!loading && templates.length === 0 && (
            <EmptyWrapper>
              <Empty description={t('iconTemplates:noTemplates')} />
            </EmptyWrapper>
          )}
        </CardWrapper>
        {isViewing && (
          <IconTemplateViewing
            {...templates.find(el => el.id === selectedTemplateId)}
          />
        )}
      </StyledPageContent>
    </PageLayout>
  );
}

IconTemplatesRoot.propTypes = {};

IconTemplatesRoot.defaultProps = {};

export default IconTemplatesRoot;
