// DO NOT EDIT - This file is automatically generated via yarn buildTranslations
// Request access here:
// https://docs.google.com/spreadsheets/d/1HQdC8YZRPFtsn1a427uh3m_hhrbqqYBUdHpct65NwJ0/edit#gid=1763868262

export default {
  common: {
    back: 'Назад',
    cancel: 'Отменить',
    close: 'Закрыть',
    email: 'Электронная почта',
    goodMorning: 'доброе утро',
    goodAfternoon: 'Добрый день',
    goodEvening: 'Добрый вечер',
    new: 'новый. /. Новейший',
    of: 'из. /. От. /. O. /. Об (all apply)',
    submit: 'Отправить',
    trackCare: 'Отслеживание обслуживания ',
    no: 'Нет',
    yes: 'Да',
  },
  login: {
    error: 'Вход в систему не удался. Пожалуйста, попробуйте еще раз.',
    password: 'Пароль',
    language: 'Язык',
    login: 'Логин',
    showPassword: 'Показать пароль',
  },
  forgotPassword: {},
  nav: {},
  careRecipients: {},
  officeCommunication: {},
  iconTemplates: {},
  familyChat: {},
  schedule: {},
  planOfCare: {},
};
