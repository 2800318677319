import React from 'react';
// import PropTypes from 'prop-types';
import { Router } from '@reach/router';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components/macro';
import appReducer from 'reducers';
import { PersistGate } from 'redux-persist/integration/react';

import NotFound from 'components/NotFound';
import PrivateRoute from 'components/PrivateRoute';

// Pages
import AuthRedirect from 'pages/AuthRedirect';
import CareRecipients from 'pages/CareRecipients';
// import Dashboard from 'pages/Dashboard';
import ForgotPassword from 'pages/ForgotPassword';
import IconTemplates from 'pages/IconTemplates';
import Login from 'pages/Login';
import Reporting from 'pages/Reporting';

import theme from 'styles/theme';

const { persistor, store } = appReducer;

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Router>
            <NotFound default />
            <Login path="/" />
            <AuthRedirect path="/auth" />
            <ForgotPassword path="/forgot-password" />
            <PrivateRoute as={CareRecipients} path="/care-recipients/*" />
            <PrivateRoute as={Reporting} path="/reporting/*" />
            <PrivateRoute as={IconTemplates} path="/icon-templates/*" />
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

App.propTypes = {};

App.defaultProps = {};

export default App;
