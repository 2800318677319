import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { ContentWrapper } from 'components/PageLayout';
import { Divider, Typography } from 'antd';

const { Title } = Typography;

const StyledChatUI = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  height: 100%;
  max-height: 100%;
`;

const TitleSection = styled(ContentWrapper)`
  height: 72px;
`;

const ListSection = styled(ContentWrapper)`
  flex: 1 1 auto;
  min-height: 1px;
  overflow-y: auto;
  padding-right: 11px;
`;

const TextEntrySection = styled(ContentWrapper)``;

function ChatUI({ list, textEntry, title, ...rest }) {
  return (
    <StyledChatUI {...rest}>
      <TitleSection>
        <Title level={5} style={{ marginBottom: 0 }}>
          {title}
        </Title>
      </TitleSection>
      <Divider style={{ margin: 0 }} />
      <ListSection>{list}</ListSection>
      <Divider style={{ margin: 0 }} />
      <TextEntrySection>{textEntry}</TextEntrySection>
    </StyledChatUI>
  );
}

ChatUI.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
};

ChatUI.defaultProps = {
  children: null,
};

export default ChatUI;
