const endpoints = {
  login: '/login',
  logout: '/logout',
  passwordReset: '/api/users/password',
  account: '/account',

  // new
  careRecipients: '/care_recipients/all',
  schedules: '/schedules',

  // old
  careRecipientDetails: '/api/care_recipients',

  // Office Communication
  getNotes: '/api/notes',
  createNote: '/api/notes/create',

  // Icon Templates
  getIcons: '/icons',
  getIconTemplates: '/icon_templates/all',
  templateCreateUpdate: '/icon_templates',

  // Family Chat
  getMessages: '/messages/all',
  familyChat: '/family_chats',
  sendMessage: '/messages',
  unreadMessages: '/messages/since_last_read',

  // Careplan Checklist
  checklist: '/care_recipients/careplan_checklist',
};

export default endpoints;
