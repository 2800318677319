import React from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from '@reach/router';

import { Button } from 'antd';

function ButtonLink({ children, state, to, ...rest }) {
  return (
    <Button onClick={() => navigate(to, { state })} {...rest}>
      <Link to={to} state={state || undefined}>
        {children}
      </Link>
    </Button>
  );
}

ButtonLink.propTypes = {
  children: PropTypes.any.isRequired,
  state: PropTypes.object,
  to: PropTypes.string.isRequired,
};

ButtonLink.defaultProps = {
  state: null,
};

export default ButtonLink;
