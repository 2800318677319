import React from 'react';
import http from 'utils/http';
import endpoints from 'utils/endpoints';

function useChecklist(recipientId) {
  const [checklist, setChecklist] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    http(`${endpoints.checklist}?care_recipient_id=${recipientId}`)
      .then(res => setChecklist(res.data.data))
      .catch(err => console.log)
      .finally(() => setLoading(false));
  }, [recipientId]);

  function saveChecklist(newChecklist) {
    setSaving(true);
    const method = checklist.length === 0 ? 'post' : 'put';
    http[method](endpoints.checklist, {
      care_recipient_id: recipientId,
      checklist: newChecklist,
    })
      .then(res => setChecklist(newChecklist))
      .catch(err => console.log)
      .finally(() => setSaving(false));
  }

  function removeItem(index) {
    const newChecklist = checklist.filter((el, i) => i !== index);
    saveChecklist(newChecklist);
  }

  function addItem(newItem) {
    const newChecklist = checklist.concat(newItem);
    saveChecklist(newChecklist);
  }

  return { addItem, checklist, loading, removeItem, saving };
}

export default useChecklist;
