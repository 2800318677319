import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Header from 'components/Header';
import { Input, Table, Typography } from 'antd';
import Loading from 'components/Loading';
import PageLayout, { PageHeader, PageContent } from 'components/PageLayout';
import { SearchOutlined } from '@ant-design/icons';

import { colors } from 'styles/theme';
import endpoints from 'utils/endpoints';
import { format } from 'date-fns';
import http from 'utils/http';
import { sendError } from 'utils/helpers';

import useColumns from './useColumns';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const ContentWrapper = styled.div`
  padding: 24px;
`;

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
`;

function RecipientList() {
  const { t } = useTranslation(['careRecipients']);
  const [recipients, setRecipients] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const userId = useSelector(state => state.auth.user.id);
  const columns = useColumns();

  React.useEffect(() => {
    setLoading(true);
    http(endpoints.careRecipients)
      .then(({ data }) => setRecipients(data.data))
      .catch(err => sendError('error fetching recipients', err))
      .finally(() => setLoading(false));
  }, []);

  function getDataSource(data) {
    return data.map(el => ({
      key: el.id,
      recipientId: el.id,
      name: `${el.first_name} ${el.last_name}`,
      careTeam: '', // This will be added in the API at some point
      carePlan: '',
      openAlerts: Math.floor(Math.random() * 100 + 1), // This will be added in the API at some point
      lastObservation: format(new Date(), 'M/d/yyyy h:mm a'),
      userId,
    }));
  }

  const filteredRecipients = search.length
    ? recipients.filter(el =>
        `${el.first_name} ${el.last_name}`
          .toLowerCase()
          .includes(search.toLowerCase())
      )
    : recipients;
  const dataSource = getDataSource(filteredRecipients);

  return (
    <PageLayout>
      <PageHeader>
        <Header title={t('careRecipients:careRecipients')} />
      </PageHeader>
      <PageContent>
        <ContentWrapper>
          <TitleWrapper>
            <Title level={5} style={{ paddingBottom: 0 }}>
              {t('careRecipients:careRecipients')}
            </Title>
            <Input
              onChange={e => setSearch(e.target.value)}
              prefix={<SearchOutlined style={{ color: colors.gray6 }} />}
              placeholder={t('common:search')}
              style={{ maxWidth: 256 }}
              value={search}
            />
          </TitleWrapper>
          {loading && <Loading />}
          {!loading && (
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={{
                defaultCurrent: 1,
                defaultPageSize: 10,
                total: filteredRecipients.length,
              }}
              rowClassName="recipient-row"
            />
          )}
        </ContentWrapper>
      </PageContent>
    </PageLayout>
  );
}

RecipientList.propTypes = {};

RecipientList.defaultProps = {};

export default RecipientList;
