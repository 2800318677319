// DO NOT EDIT - This file is automatically generated via yarn buildTranslations
// Request access here:
// https://docs.google.com/spreadsheets/d/1HQdC8YZRPFtsn1a427uh3m_hhrbqqYBUdHpct65NwJ0/edit#gid=1763868262

export default {
  common: {
    back: 'Arrière',
    cancel: 'Annuler',
    close: 'Fermer',
    date: 'Date',
    email: 'Email',
    goodMorning: 'Bonjour',
    goodAfternoon: 'Bonne après-midi',
    goodEvening: 'Bonsoir',
    new: 'Nouveau',
    remove: 'Retirer',
    next: 'Suivant (male), Suivante (female)',
    of: 'de',
    select: 'Sélectionner',
    startTyping: 'Commencer à écrire',
    submit: 'Soumettre',
    time: 'Temps',
    trackCare: 'Suivi des Soins',
    no: 'Non',
    yes: 'Oui',
  },
  login: {
    username: "Nom d'utilisateur",
    error: 'La connexion a échoué, veuillez réessayer.',
    password: 'Mot de passe',
    language: 'Langue',
    login: "S'identifier",
    showPassword: 'Montrer le Mot de Passe',
    forgotPassword: 'Mot de passe oublié',
  },
  forgotPassword: {
    error:
      '\nLa réinitialisation du mot de passe a échoué, veuillez réessayer.',
    success:
      'vérifiez votre messagerie pour les instructions de réinitialisation du mot de passe.',
    title: '\nRéinitialisation du mot de passe',
  },
  nav: {},
  careRecipients: {},
  officeCommunication: {},
  iconTemplates: {},
  familyChat: {},
  schedule: {},
  planOfCare: {},
};
