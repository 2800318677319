import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Select } from 'antd';

import { useTranslation } from 'react-i18next';

const { Option } = Select;

const Label = styled.span`
  margin: 0 8px;
`;

function FrequencyCell({ handleIconUpdate, icon, ...rest }) {
  const { t } = useTranslation(['iconTemplates']);
  const values = new Array(10).fill('').map((el, i) => i + 1);
  const unitValues = ['hour', 'day', 'week'];
  // We don't show frequency if we don't have an alert configured.
  if (!icon.alert_setting) {
    return null;
  }
  return (
    <>
      <Select
        onChange={value =>
          handleIconUpdate({
            icon,
            value: parseInt(value, 10),
            key: 'frequency',
          })
        }
        style={{ minWidth: 30 }}
        value={icon.alert_setting.frequency}
      >
        {values.map((option, i) => (
          <Option key={i} value={option}>
            {option}
          </Option>
        ))}
      </Select>
      <Label>{t('iconTemplates:frequencyLabel')}</Label>
      <Select
        onChange={value => handleIconUpdate({ icon, value, key: 'unit' })}
        style={{ minWidth: 80 }}
        value={icon.alert_setting.unit}
      >
        {unitValues.map((option, i) => (
          <Option key={i} value={option}>
            {option}
          </Option>
        ))}
      </Select>
    </>
  );
}

FrequencyCell.propTypes = {
  handleIconUpdate: PropTypes.func.isRequired,
  icon: PropTypes.any.isRequired,
};

FrequencyCell.defaultProps = {};

export default FrequencyCell;
