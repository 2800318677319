import React from 'react';
import styled from 'styled-components';
import endpoints from 'utils/endpoints';
import http from 'utils/http';
import { navigate } from '@reach/router';

import { Button, Input, message, Space, Typography } from 'antd';
import { Link } from 'components';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { authSuccess } from 'reducers/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const Layout = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray4};
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

const Content = styled.form`
  align-items: stretch;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  width: 330px;
  padding: 24px;
`;

const USERNAME = process.env.REACT_APP_USERNAME;
const PASSWORD = process.env.REACT_APP_PASSWORD;

function Login(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['login']);
  const [login, setLogin] = React.useState(USERNAME || '');
  const [password, setPassword] = React.useState(PASSWORD || '');
  const [loading, setLoading] = React.useState(false);
  const token = useSelector(state => state.auth.token);

  React.useEffect(() => {
    if (token) {
      // This will be present if a user's token was expired and they were on
      // a private route. We navigate them back to the page they were at.
      const params = new URL(document.location).searchParams;
      const from = params.get('from');
      navigate(from ? decodeURI(from) : '/care-recipients');
    }
  }, [token]);

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    http
      .post(endpoints.login, { login, password })
      .then(({ data }) => {
        const { policies } = data;
        const { user, token } = data.data;
        dispatch(authSuccess({ user: user, policies, token }));
      })
      .catch(err => {
        console.log('error logging in:', { err });
        message.error(t('login:error'));
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <Layout>
      <Content onSubmit={handleSubmit}>
        <Space direction="vertical" size={24}>
          <Title level={3} style={{ textAlign: 'center', marginBottom: 0 }}>
            {t('login:title')}
          </Title>
          <Input
            placeholder={t('login:username')}
            name="username"
            onChange={e => setLogin(e.target.value)}
            required
            size="large"
            value={login}
          />
          <Input.Password
            iconRender={visible =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            name="password"
            onChange={e => setPassword(e.target.value)}
            placeholder={t('login:password')}
            required
            size="large"
            type="password"
            value={password}
          />
          <Link to="/forgot-password">{t('login:forgotPassword')}</Link>
          <Button
            block
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
          >
            {t('login:login')}
          </Button>
        </Space>
      </Content>
    </Layout>
  );
}

export default Login;
