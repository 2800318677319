import React from 'react';
import { useTranslation } from 'react-i18next';

function useColumns() {
  const { t } = useTranslation(['common', 'schedules']);
  const columns = [
    {
      dataIndex: 'startTime',
      title: t('schedule:startTime'),
      render: (text, data) => text,
    },
    {
      dataIndex: 'endTime',
      title: t('schedule:endTime'),
      render: (text, data) => text,
    },
    {
      dataIndex: 'caregiver',
      title: t('schedule:caregiver'),
      render: (text, data) => text,
    },
    {
      dataIndex: 'email',
      title: t('common:email'),
      render: (text, data) => <a href={`mailto:${text}`}>{text}</a>,
    },
    {
      dataIndex: 'phoneNumber',
      title: t('common:phoneNumber'),
      render: (text, data) => text,
    },
  ];
  return columns;
}

export default useColumns;
